import { useContext, useEffect, useState } from "react";
import { OptionsContext } from "../../Contexts";

import useSearchProvider from "../../Utilities/Search/search"
import { formatCurrency, useFunctionsFetch } from "../../UtilFunctions";
import Button from "../../Utilities/Button/button";
import OperationMessage from "../../Utilities/OperationMessage/operationmessage";
import "./tambahproduk.css";

export default function KelolaProduk(props) {
  const optionsBinding = useContext(OptionsContext)
  
  const [callGetPC, setCallGetPC] = useState(1);
  const totalProdCount = useFunctionsFetch(0, "/system/persistent-data/accumulatedData/totalProductCount", {}, optionsBinding["manage-items"], callGetPC, () => { setCallGetPC((prev) => { return prev - 1; }) });
  const [formMessage, setFormMessage] = useState([false, ""])
  const [formData, setFormData] = useState({
    p_name: "",
    s_price: "",
    format_s_price: "",
    s_price_b: "",
    format_s_price_b: "",
    qty_pack: "",
    unit: "",
    packaging: "",
    ingredients: "",
    category: "",
    pid: 0
  });
  const unitsData = useFunctionsFetch([], "/system/persistent-data/productFormats/units", {}, optionsBinding["manage-items"]);
  const [unitSearch, clearUnitSearch, queryUnitSearch, unitKeyDownHandler] = useSearchProvider(
    unitsData.map((unit) => ({ unit: unit })), "unit", setFormData, "unit", 5);
  const packagingsData = useFunctionsFetch([], "/system/persistent-data/productFormats/packagings", {}, optionsBinding["manage-items"]);
  const [packagingSearch, clearPackagingSearch, queryPackagingSearch, packagingKeyDownHandler] = useSearchProvider(
    packagingsData.map((packaging) => ({ packaging: packaging })), "packaging", setFormData, "packaging", 5);
  const categorysData = useFunctionsFetch([], "/system/persistent-data/productFormats/categorys", {}, optionsBinding["manage-items"]);
  const [categorySearch, clearCategorySearch, queryCategorySearch, categoryKeyDownHandler] = useSearchProvider(
    categorysData.map((category) => ({ category: category })), "category", setFormData, "category", 5);

  const [callCheckItem, setCallCheckItem] = useState(0)
  useFunctionsFetch(undefined, "/products/check-exists", formData, optionsBinding["manage-items"], callCheckItem, processCheck);
  const [callSendNewItem, setCallSendNewItem] = useState(0)
  useFunctionsFetch(undefined, "/products/create", formData, optionsBinding["manage-items"], callSendNewItem, processSendComplete);

  useEffect(() => {
    setFormData((prev) => ({...prev, pid: totalProdCount + 1}))
  }, [totalProdCount])

  function handleFormChange(changeEvt) {

    if ((changeEvt.target.id === "qty_pack")
    || (changeEvt.target.id === "s_price")
    || (changeEvt.target.id === "s_price_b")) { 
      if (!(/^([1-9][\d\,]*)?$/.test(changeEvt.target.value))) {
        return
      } 
    } else if ((changeEvt.target.id === "unit")
    || (changeEvt.target.id === "packaging")
    || (changeEvt.target.id === "category")) {
      if (!(/^[^\d]*$/.test(changeEvt.target.value))) {
        return
      }
    }

    if ((changeEvt.target.id === "s_price") 
    || (changeEvt.target.id === "s_price_b")) {
      let purenum = changeEvt.target.value.replaceAll(",", "")
      let formatString = formatCurrency(purenum)

      setFormData((prevData) => ({
        ...prevData,
        [changeEvt.target.id]: purenum,
        [`format_${changeEvt.target.id}`]: formatString
      }));

    } else {
      setFormData((prevData) => ({
        ...prevData,
        [changeEvt.target.id]: changeEvt.target.value
      }));
    }

    if (changeEvt.target.id === "unit") queryUnitSearch(changeEvt.target.value);
    if (changeEvt.target.id === "packaging") queryPackagingSearch(changeEvt.target.value);
    if (changeEvt.target.id === "category") queryCategorySearch(changeEvt.target.value);

  }

  function addItemHandler() {
    let c1 = (formData.p_name.length > 0)
    let c2 = (formData.qty_pack.length > 0)
    let c3 = (formData.unit.length > 0)
    let c4 = (formData.packaging.length > 0)
    let c5 = (formData.s_price.length > 0)
    let c6 = (formData.category.length > 0)
    let c7 = (unitsData.filter((option) => { return (option === formData.unit); }).length === 1)
    let c8 = (packagingsData.filter((option) => { return (option === formData.packaging); }).length === 1)
    let c9 = (categorysData.filter((option) => { return (option === formData.category); }).length === 1)
    if (c1 && c2 && c3 && c4 && c5 && c6 && c7 && c8 && c9) {
      setCallCheckItem((prev) => { return prev + 1; });
    } else {
      if (!c1) {setFormMessage([1, "Nama produk baru tidak boleh kosong!"])} 
      else if (!c2) {setFormMessage([2, "Jumlah produk baru tidak boleh kosong!"])} 
      else if (!c3) {setFormMessage([3, "Satuan produk baru tidak boleh kosong!"])} 
      else if (!c4) {setFormMessage([4, "Packaging produk baru tidak boleh kosong!"])} 
      else if (!c5) {setFormMessage([5, "Harga jual produk baru tidak boleh kosong!"])} 
      else if (!c6) {setFormMessage([6, "Kategori produk baru tidak boleh kosong!"])} 
      else if (!c7) {setFormMessage([7, "Satuan produk harus sesuai dengan pilihan yang diberikan!"])} 
      else if (!c8) {setFormMessage([8, "Packaging produk harus sesuai dengan pilihan yang diberikan!"])} 
      else if (!c9) {setFormMessage([9, "Kategori produk harus sesuai dengan pilihan yang diberikan!"])}
      setTimeout(() => {setFormMessage([false, ""])}, 10000)
    }
  }

  function processCheck(checkResult)  {
    if (checkResult === null) {
      setFormMessage([false, ""])
      setCallSendNewItem((prev) => { return prev + 1; });
    } else if (checkResult === "exists") {
      setFormMessage([100, `Produk '${formData.p_name} ( ${formData.qty_pack} ${formData.unit} / ${formData.packaging} )' sudah terdaftar di sistem!`])
      setTimeout(() => {setFormMessage([false, ""])}, 10000)
    }
    setCallCheckItem((prev) => { return prev - 1; });
  }

  function processSendComplete(newItemResponse) {
    if ((newItemResponse) && (newItemResponse.acknowledged === true)) {
      setFormMessage(
        [true, 
        `Produk '${formData.p_name} ( ${formData.qty_pack} ${formData.unit} / ${formData.packaging} )' berhasil terdaftar di sistem!`]
      );
      setTimeout(() => {setFormMessage([false, ""])}, 10000)
      setFormData({
        p_name: "",
        s_price: "",
        format_s_price: "",
        s_price_b: "",
        format_s_price_b: "",
        qty_pack: "",
        unit: "",
        packaging: "",
        ingredients: "",
        category: "",
        pid: 0
      });
      setCallGetPC((prev) => { return prev + 1; });
      props.refreshProducts();
      props.closePopup();
    }
    setCallSendNewItem((prev) => { return prev - 1; });
  }

  return (
    <div className="tambahBarang">
      <h5 className="sectionHeading">Tambah Produk Baru</h5>

      <div style={{ height: "0.3em" }}></div>
      <hr />
      <div style={{ height: "1em" }}></div>
      <form autoComplete="off">
        <h6 className="sectionHeading mb-4">Informasi Produk</h6>
        <div>
          <div style={{width: "90px"}}>
            <label htmlFor="product_id">ID <span className="reqStar">*</span></label><br />
            <input id="product_id" type="text" onChange={handleFormChange} value={formData.pid} disabled /> 
          </div>
          <div style={{ flexGrow: "3" }} className={`${(formMessage[0] === 1) ? "inputError" : ""}`}>
            <label htmlFor="p_name">Nama Produk <span className="reqStar">*</span></label><br />
            <input id="p_name" type="text" onChange={handleFormChange} value={formData.p_name} /> 
          </div>
          <div style={{ width: "210px" }} className={`${(formMessage[0] === 6) || (formMessage[0] === 9) ? "inputError" : ""}`}>
            <label htmlFor="category">
              Kategori <span className="reqStar">*</span>
            </label><br />
            <input 
              id="category" 
              type="text" 
              onChange={handleFormChange} 
              value={formData.category} 
              onFocus={() => {queryCategorySearch(formData.category)}}
              onBlur={() => {clearCategorySearch()}}
              onKeyDown={categoryKeyDownHandler}
            /> 
            {categorySearch.length > 0 ? 
              <div className={`category searchContainer`}>
                {categorySearch}
              </div> 
            : ""}
          </div>
        </div>
        <div>
          <div style={{ flexGrow: "1" }}>
            <label htmlFor="ingredients">Komposisi Produk</label><br />
            <input id="ingredients" type="text" onChange={handleFormChange} value={formData.ingredients}/> 
          </div>
        </div>
        <h6 className="sectionHeading mt-4 mb-4">Data Penjualan Produk</h6>
        <div>
          <div style={{ flexGrow: "1" }} className={`${(formMessage[0] === 2) ? "inputError" : ""}`}>
            <label htmlFor="qty_pack">Isi <span className="reqStar">*</span></label><br />
            <input id="qty_pack" type="text" onChange={handleFormChange} value={formData.qty_pack} /> 
          </div>
          <div style={{ flexGrow: "1" }} className={`${(formMessage[0] === 3) || (formMessage[0] === 7) ? "inputError" : ""}`}>
            <label htmlFor="unit">
              Satuan Produk <span className="reqStar">*</span>
            </label><br />
            <input 
              id="unit" 
              type="text" 
              onChange={handleFormChange} 
              value={formData.unit} 
              onFocus={() => {queryUnitSearch(formData.unit)}}
              onBlur={() => {clearUnitSearch()}}
              onKeyDown={unitKeyDownHandler}
            /> 
            {unitSearch.length > 0 ? 
              <div className={`searchContainer`}>
                {unitSearch}
              </div> 
            : ""}
          </div>
          <div style={{ fontSize: "18px", marginTop: "34.4px", marginRight: "16px", color: "var(--c-secondary)" }}>per</div>
          <div style={{ flexGrow: "1" }} className={`${(formMessage[0] === 4) || (formMessage[0] === 8) ? "inputError" : ""}`}>
            <label htmlFor="packaging">
              Packaging <span className="reqStar">*</span>
            </label><br />
            <input 
              id="packaging" 
              type="text" 
              onChange={handleFormChange} 
              value={formData.packaging} 
              onFocus={() => {queryPackagingSearch(formData.packaging)}}
              onBlur={() => {clearPackagingSearch()}}
              onKeyDown={packagingKeyDownHandler}
            /> 
            {packagingSearch.length > 0 ? 
              <div className={`packaging searchContainer`}>
                {packagingSearch}
              </div> 
            : ""}
          </div>
        </div>
        <div>
          <div style={{ flexGrow: "1" }} className={`${(formMessage[0] === 5) ? "inputError" : ""}`}>
            <label htmlFor="s_price">Harga Jual <span className="reqStar">*</span></label><br />
            <div style={{ position: "absolute", fontSize: "18px", top: "34.4px", left: "21px", userSelect: "none", zIndex: "-1"}}>Rp</div>
            <input 
              id="s_price" 
              type="text" 
              onChange={handleFormChange} 
              value={formData.format_s_price} 
              style={{ padding: "0.8em 20px 0.6em 50px", backgroundColor: "transparent" }}
            /> 
          </div>
          <div style={{ flexGrow: "2" }}>
            <label htmlFor="s_price_b">Harga Jual Diskon</label><br />
            <div style={{ position: "absolute", fontSize: "18px", top: "34.4px", left: "21px", userSelect: "none", zIndex: "-1"}}>Rp</div>
            <input 
              id="s_price_b" 
              type="text" 
              onChange={handleFormChange} 
              value={formData.format_s_price_b} 
              style={{ padding: "0.8em 20px 0.6em 50px", backgroundColor: "transparent" }}
            /> 
          </div>
        </div>
        <div style={{ height: "2em" }}></div>
        <div className="mb-4">
          <div>
            <Button 
              className="cancelAddProductBtn"
              iconClasses="bi bi-x-circle" 
              btnText="Batal" 
              clickHandler={(clickEvt) => {
                clickEvt.preventDefault();
                props.closePopup();
              }} 
              type="button" 
            />
          </div>
          <div>
            <Button 
              className="addProductBtn"
              iconClasses="bi bi-check-circle" 
              btnText="Konfirmasi" 
              clickHandler={addItemHandler} 
              type="button" 
            />
          </div>
        </div>
      </form>

      <OperationMessage status={formMessage[0] === false ? 0 : formMessage[0] === true ? 1 : 2} msg={formMessage[1]} />
    </div>
  )
}