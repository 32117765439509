import { useContext, useState } from "react";
import { OptionsContext, UserContext } from "../../Contexts";
import { useFunctionsFetch } from "../../UtilFunctions";
import Button from "../../Utilities/Button/button";
import OperationMessage from "../../Utilities/OperationMessage/operationmessage";
import Toggler from "../../Utilities/Toggler/toggler";


export default function TambahCustomer(props) {
  const optionsBinding = useContext(OptionsContext);
  const [user] = useContext(UserContext);
  
  // Add Customer Function
  const [addCustomerMessage, setAddCustomerMessage] = useState([0, "", null]);
  const [newCustomerData, setNewCustomerData] = useState({
    name: "",
    tel: "",
    email: "",
    addr: "",
    typ: "r"
  });
  const [newCustomerBody, setNewCustomerBody] = useState(null);
  useFunctionsFetch(
    undefined,
    "/customers/create",
    newCustomerBody,
    props.callerTab,
    newCustomerBody,
    (res) => {
      setNewCustomerBody(null);
      if (res.acknowledged === true) {
        props.successPopup();
      } else if (res.acknowledged === false && res.err && res.err === "exists") {
        setAddCustomerMessage([
          2, 
          "Nomor telepon customer sudah pernah terdaftar di dalam sistem!", 
          setTimeout(() => { setAddCustomerMessage([0, "", null]); }, 5000)
        ]);
      } else {
        setAddCustomerMessage([
          2, 
          "Terjadi kesalahan server dalam pendaftaran customer!", 
          setTimeout(() => { setAddCustomerMessage([0, "", null]); }, 5000)
        ]);
      }
    }
  )

  function addCustomerFormChange(changeEvt) {
    if (changeEvt.target.name === 'tel') {
      if (!/^(0[\d]{0,14})?$/.test(changeEvt.target.value)) return;
    }
    if (changeEvt.target.name === 'name') {
      if (!/^[\w\d\s\-]*$/.test(changeEvt.target.value)) return;
    }
    setNewCustomerData((prev) => ({
      ...prev,
      [changeEvt.target.name]: changeEvt.target.value
    }))
  }

  function confirmAddCustomer(submitEvt) {
    submitEvt.preventDefault();
    clearTimeout(addCustomerMessage[2]);
    setAddCustomerMessage([0, "", null]);

    try {
      if (
        (!(newCustomerData.name.length > 0))
        || (!(newCustomerData.tel.length > 0))
      ) throw "Customer harus mempunyai nama dan nomor telepon!";
      if (
        (newCustomerData.email.length > 0) 
        && (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(newCustomerData.email))
      ) throw "Customer tidak mempunyai email yang valid!";

      setNewCustomerBody({
        ...newCustomerData
      });
    } catch (err) {
      setAddCustomerMessage([
        2, 
        err, 
        setTimeout(() => { setAddCustomerMessage([0, "", null]); }, 5000)
      ]);
    }

  }

  // Rendered Component
  return (
    <div>
      <div className="addCustomerCard">
        <h5>Tambah Customer Baru</h5>

        <div style={{ height: "0.3em" }}></div>
        <hr />
        <div style={{ height: "1em" }}></div>

        <h6 className="sectionHeading mb-4">Data Customer</h6>
        <form onSubmit={confirmAddCustomer}>
          <div>
            <div style={{ flexGrow: "4" }}>
              <label htmlFor="newcustomer_name">
                Nama Customer <span className="reqStar">*</span>
              </label><br />
              <input 
                id="newcustomer_name" 
                type="text" 
                onChange={addCustomerFormChange} 
                value={newCustomerData.name} 
                name="name"
              /> 
            </div>
            <div style={{ flexGrow: "1" }}>
              <label htmlFor="newcustomer_tel">
                Nomor Telepon <span className="reqStar">*</span>
              </label><br />
              <input 
                id="newcustomer_tel" 
                type="text" 
                onChange={addCustomerFormChange} 
                value={newCustomerData.tel} 
                name="tel"
                placeholder="0XXXXXXXXXXX"
              /> 
            </div>
          </div>
          <div>
            <div style={{ flexGrow: "7" }}>
              <label htmlFor="newcustomer_tel">
                Email
              </label><br />
              <input 
                id="newcustomer_email" 
                type="text" 
                onChange={addCustomerFormChange} 
                value={newCustomerData.email} 
                name="email"
                placeholder="contoh@domain.tld"
              /> 
            </div>
          </div>
          <div>
            <div style={{ flexGrow: "1" }}>
              <label htmlFor="newcustomer_tel">
                Alamat
              </label><br />
              <input 
                id="newcustomer_addr" 
                type="text" 
                onChange={addCustomerFormChange} 
                value={newCustomerData.addr} 
                name="addr"
              /> 
            </div>
          </div>
          {user.perms.includes(optionsBinding['set-vip']) ? 
            <div style={{ alignItems: "center", marginTop: "2em" }}>
              <div>
                <Toggler 
                  options={[
                    [
                      "Regular Customer", 
                      newCustomerData.typ === "r", 
                      () => { setNewCustomerData((prev) => ({
                        ...prev,
                        typ: "r"
                      })); }
                    ],
                    [
                      "VIP Customer", 
                      newCustomerData.typ === "v", 
                      () => { setNewCustomerData((prev) => ({
                        ...prev,
                        typ: "v"
                      })); }
                    ]
                  ]} 
                  style={{ fontSize:"18px", padding: "0.6em 1em" }}
                  className="togglerSecondaryColor" 
                />
              </div>
            </div>
          : ""}
        </form>

        <OperationMessage status={addCustomerMessage[0]} msg={addCustomerMessage[1]}  />

        <div className="cardButtons">
          <Button 
            className="tambahCustomer-cancel"
            iconClasses="bi bi-x-circle" 
            btnText="Batal" 
            clickHandler={props.closePopup} 
            type="button" 
          />
          <Button 
            className="tambahCustomer-confirm"
            iconClasses="bi bi-check-circle" 
            btnText="Konfirmasi" 
            clickHandler={confirmAddCustomer} 
            type="submit" 
          />
        </div>
      </div>
    </div>
  )
}