import { useState } from 'react';
import { useFunctionsFetch } from '../UtilFunctions';
import './clientpanel.css';


import Topbar from '../Topbar/topbar';
import Sidebar from '../Sidebar/sidebar';
import Beranda from './Beranda/beranda';
import Kasir from './Kasir/kasir';
import Customer from './Customer/customer';
import TambahPembelian from './Pembelian/tambahpembelian';
import ReturnPembelian from './Pembelian/returnpembelian';
import KelolaProduk from './KelolaProduk/kelolaproduk';
import LaporanPembelian from './LaporanPembelian/laporanpembelian';
import LaporanPenjualan from './LaporanPenjualan/laporanpenjualan';
import Logout from './Logout/logout';
import { OptionsContext, PermissionsContext } from '../Contexts';

export default function ClientPanel(props) {
  let [activeTab, setActiveTab] = useState((props.lastTab === undefined) ? 1 : props.lastTab);
  let [saveData, setSaveData] = useState({});
  let optionsBinding = useFunctionsFetch({}, "/system/bindings", {});
  let [perms, setPerms] = useState({});
  useFunctionsFetch({}, "/system/permission-bindings", {}, 1, true, (res) => {
    setPerms(res);
  })

  let currentContent;

  switch (activeTab) {
    case optionsBinding["beranda"]: 
      currentContent = <Beranda />;
      break;
    case optionsBinding["kasir"]: 
      currentContent = <Kasir saveData={saveData} setSaveData={setSaveData} />;
      break;
    case optionsBinding["manage-customers"]: 
      currentContent = <Customer />;
      break;
    case optionsBinding["add-purch"]: 
      currentContent = <TambahPembelian changeActiveTab={setActiveTab} />;
      break;
    case optionsBinding["return-purch"]: 
      currentContent = <ReturnPembelian />;
      break;
    case optionsBinding["manage-items"]: 
      currentContent = <KelolaProduk />;
      break;
    case optionsBinding["report-purchases"]: 
      currentContent = <LaporanPembelian />;
      break;
    case optionsBinding["report-sales"]: 
      currentContent = <LaporanPenjualan />;
      break;
    case optionsBinding["logout"]: 
      currentContent = <Logout />;
      break;
  }

  return (
    <PermissionsContext.Provider value={perms}>
      <OptionsContext.Provider value={optionsBinding}>
        <div className='mainInterface'>
          <Topbar />
          <Sidebar activeTab={activeTab} setActiveTab={setActiveTab} />

          <div className='mainContent'>
            {currentContent}
          </div>
        </div>
      </OptionsContext.Provider>
    </PermissionsContext.Provider>
  )
}