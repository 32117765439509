import "./topbar.css";

import pcLogo from "../../assets/images/pc-apotek-logo.svg";
import { useContext, useState } from "react";
import { UserContext } from "../Contexts";
import { useFunctionsFetch } from "../UtilFunctions";

export default function Topbar(props) {
  const currentVersion = "1.21";

  const [user] = useContext(UserContext);
  const [latestVersion, setLatestVersion] = useState("0");
  useFunctionsFetch(
    undefined,
    "/system/version",
    {},
    1,
    true,
    (res) => { 
      setLatestVersion(res.data); 
    }
  )

  let verStatus;
  let currentFloat = parseFloat(currentVersion);
  let latestFloat = parseFloat(latestVersion);
  if (latestFloat === 0) verStatus = "Checking";
  if (latestFloat === currentFloat) verStatus = "Latest";
  if (latestFloat > currentFloat) {
    verStatus = "Outdated";
    window.location.reload(true);
  };
  if (latestFloat < currentFloat) verStatus = "Dev";

  return (
    <div className="topbar">
      <div className="currentUserSection">
        <i className="bi bi-person-circle"></i>
        <div className="userDetails">
          {user.displayName} <br/>
          <span className="apotekAlamat">Permata Care TPI 1</span>
        </div>
      </div>
      <div className="apotekLogo">
        <img src={pcLogo} />
      </div>
      <div className="version">
         Version {currentVersion} ( {verStatus} )
      </div>
    </div>
  ); 
}