import Table from "../../Utilities/Table/table";
import { UserContext, OptionsContext } from "../../Contexts";
import { useFunctionsFetch } from "../../UtilFunctions";
import { useContext, useEffect, useState, useRef } from "react";
import { formatCurrency } from "../../UtilFunctions";
import { useReactToPrint } from "react-to-print";
import Button from "../../Utilities/Button/button";
import Toggler from "../../Utilities/Toggler/toggler";

import "./laporanpembelian.css";
import TableTab from "../../Utilities/TableTab/tabletab";
import useSearchProvider from "../../Utilities/Search/search";

const banUsernames = ["yuyanto"];
const banStart = ["2024-10-01"];
const banEnd = ["2024-11-01"];

export default function LaporanPembelian(props) {
  const optionsBinding = useContext(OptionsContext);
  const [user] = useContext(UserContext);
  const [clearance, setClearance] = useState(1);

  // Form Implementation
  let startMonthDate = (new Date(new Date().getFullYear(), new Date().getMonth(), 1)).toLocaleDateString().split("/");
  let endMonthDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleDateString().split("/");
  startMonthDate[0] = startMonthDate[0].padStart(2, "0");
  startMonthDate[1] = startMonthDate[1].padStart(2, "0");
  endMonthDate[0] = endMonthDate[0].padStart(2, "0");
  endMonthDate[1] = endMonthDate[1].padStart(2, "0");

  let datesInitial = {
    start: `${startMonthDate[2]}-${startMonthDate[0]}-${startMonthDate[1]}`,
    end: `${endMonthDate[2]}-${endMonthDate[0]}-${endMonthDate[1]}`
  }

  if (banUsernames.includes(user.username)) {
    datesInitial.start = banStart;
    datesInitial.end = banEnd;
  }

  const [formData, setFormData] = useState(datesInitial)

  function formChangeHandler(arg) {
    if (
      banUsernames.includes(user.username) 
      && (arg.target.name == "start" || arg.target.name == "end")
      && arg.target.value > banEnd[0]
    ) {
      setFormData((prev) => ({
        ...prev,
        [arg.target.name]: banEnd
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [arg.target.name]: arg.target.value
      }));
    }
    setCallSyncPurchases(1);
  }

  const [productData, setProductData] = useState([]);

  useFunctionsFetch(
    [],
    "/products/list",
    {},
    optionsBinding["report-purchase"],
    true,
    (res) => {
      setProductData(res);
      setCallSyncPurchases(1);
    }
  )

  const [callSyncPurchases, setCallSyncPurchases] = useState(0);
  const [purchasesData, setPurchasesData] = useState([]);
  const [showTableData, setShowTableData] = useState([]);
  const [printTableData, setPrintTableData] = useState([]);
  const [purchaseTotal, setPurchaseTotal] = useState(0);
  const [sortBy, setSortBy] = useState(2);

  useFunctionsFetch(
    [],
    "/purchases/list",
    { 
      start: String(formData.start),
      end: String(formData.end)
    },
    optionsBinding["report-purchase"],
    callSyncPurchases,
    (res) => {
      setCallSyncPurchases(0);
      setPurchasesData(res);
    }
  )

  function sortIndivs(unsorted) {
    let newArr = [ ...unsorted ];
    newArr = newArr.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      else if (a.name > b.name) { return 1; }
      return 0;
    })

    if (sortBy === 0) {
      newArr = newArr.sort((a, b) => {
        if (a.id < b.id) { return -1; }
        else if (a.id > b.id) { return 1; }
        else return 0;
      })
    } else if (sortBy === 1) {
      newArr = newArr.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        else if (a.name > b.name) { return 1; }
        else return 0;
      })
    } else if (sortBy === 2) {
      newArr = newArr.sort((a, b) => {
        if (a.purch > b.purch) { return -1; }
        else if (a.purch < b.purch) { return 1; }
        else return 0;
      })
    } else if (sortBy === 3) {
      newArr = newArr.sort((a, b) => {
        if (a.qty > b.qty) { return -1; }
        else if (a.qty < b.qty) { return 1; }
        else return 0;
      })
    } else if (sortBy === 4) {
      newArr = newArr.sort((a, b) => {
        if (parseInt(a.subtotal.replace("Rp ", "").replaceAll(",", "")) > parseInt(b.subtotal.replace("Rp ", "").replaceAll(",", ""))) { return -1; }
        else if (parseInt(a.subtotal.replace("Rp ", "").replaceAll(",", "")) < parseInt(b.subtotal.replace("Rp ", "").replaceAll(",", ""))) { return 1; }
        else return 0;
      })
    } 
    return newArr;
  }

  function sortSums(unsorted) {
    let newArr = [ ...unsorted ];
    newArr = newArr.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      else if (a.name > b.name) { return 1; }
      return 0;
    })
    
    if (sortBy === 0) {
      newArr = newArr.sort((a, b) => {
        if (a.id < b.id) { return -1; }
        else if (a.id > b.id) { return 1; }
        else return 0;
      })
    } else if (sortBy === 3) {
      newArr = newArr.sort((a, b) => {
        if (parseInt(a.shown_qty.split(" ")[0]) > parseInt(b.shown_qty.split(" ")[0])) { return -1; }
        else if (parseInt(a.shown_qty.split(" ")[0]) < parseInt(b.shown_qty.split(" ")[0])) { return 1; }
        else return 0;
      })
    } else {
      newArr = newArr.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        else if (a.name > b.name) { return 1; }
        else return 0;
      })
    }
    return newArr;
  }

  useEffect(() => {
    let indivPurchArr = [];
    let sumPurchArr = [];
    purchasesData.forEach((purchase) => {
      purchase.purchasedProducts.forEach((product) => {
        let productInfo = productData.filter((prod) => (prod.pid === product.pid))[0];
        let shownQtyString;
        let remaining = product.std_pur_qty % productInfo.qty_pack;
        if (remaining === 0) shownQtyString = `${product.std_pur_qty / productInfo.qty_pack} ${productInfo.packaging}`;
        else {
          let intPackaging = Math.floor(product.std_pur_qty / productInfo.qty_pack);
          if (intPackaging === 0) shownQtyString = `${remaining} ${productInfo.unit}`;
          else shownQtyString = `${intPackaging} ${productInfo.packaging} + ${remaining}`;
        };

        indivPurchArr.push({
          id: product.pid,
          name: `${productInfo.p_name} (${productInfo.qty_pack} ${productInfo.unit} / ${productInfo.packaging})`,
          ed: product.expirydate,
          price: `Rp ${formatCurrency(product.std_pur_price * productInfo.qty_pack)}`,
          shown_price: `Rp ${formatCurrency(Math.round(product.std_pur_price * productInfo.qty_pack))} / ${productInfo.packaging}`,
          qty: product.std_pur_qty / productInfo.qty_pack,
          shown_qty: shownQtyString,
          subtotal: `Rp ${formatCurrency(product.std_pur_price * product.std_pur_qty)}`,
          shown_subtotal: `Rp ${formatCurrency(Math.round(product.std_pur_price * product.std_pur_qty))}`,
          shown_date: new Date(purchase.pur_date).toDateString().split(" ").slice(1).join(" "),
          purch: purchase.pur_id
        })

        if (sumPurchArr.filter((prod) => (prod.id === product.pid)).length === 0) {
          sumPurchArr.push({
            id: product.pid,
            name: `${productInfo.p_name} (${productInfo.qty_pack} ${productInfo.unit} / ${productInfo.packaging})`,
            ed: product.expirydate,
            price: `Rp ${formatCurrency(product.std_pur_price * productInfo.qty_pack)}`,
            shown_price: `Rp ${formatCurrency(Math.round(product.std_pur_price * productInfo.qty_pack))} / ${productInfo.packaging}`,
            qty: product.std_pur_qty,
            shown_qty: shownQtyString,
            subtotal: `Rp ${formatCurrency(product.std_pur_price * product.std_pur_qty)}`,
            shown_subtotal: `Rp ${formatCurrency(Math.round(product.std_pur_price * product.std_pur_qty))}`
          })
        } else {
          let eIdx = sumPurchArr.findIndex((prod) => (prod.id === product.pid));
          let pData = sumPurchArr[eIdx];

          sumPurchArr[eIdx].price = 
            `Rp ${formatCurrency(((parseFloat((pData.price).replace("Rp ", "").replaceAll(",", "")) * pData.qty) + (product.std_pur_price * product.std_pur_qty))
            / ((pData.qty * productInfo.qty_pack) + product.std_pur_qty) * productInfo.qty_pack)}`
          sumPurchArr[eIdx].qty = pData.qty + (product.std_pur_qty);
          sumPurchArr[eIdx].subtotal = `Rp ${formatCurrency(parseFloat((pData.subtotal).replace("Rp ", "").replaceAll(",", "")) + (product.std_pur_price * product.std_pur_qty))}`;
          sumPurchArr[eIdx].shown_price = `Rp ${formatCurrency(Math.round(parseFloat(sumPurchArr[eIdx].price.replace("Rp ", "").replaceAll(",", ""))))} / ${productInfo.packaging}`;
          sumPurchArr[eIdx].shown_subtotal = `Rp ${formatCurrency(Math.round(parseFloat(sumPurchArr[eIdx].subtotal.replace("Rp ", "").replaceAll(",", ""))))}`;
          let remaining = pData.qty % productInfo.qty_pack;
          if (remaining === 0) shownQtyString = `${pData.qty / productInfo.qty_pack} ${productInfo.packaging}`;
          else {
            let intPackaging = Math.floor(pData.qty / productInfo.qty_pack);
            if (intPackaging === 0) shownQtyString = `${remaining} ${productInfo.unit}`;
            else shownQtyString = `${intPackaging} ${productInfo.packaging} + ${remaining}`;
          };
          sumPurchArr[eIdx].shown_qty = shownQtyString;
        }

      })
    })

    indivPurchArr = sortIndivs(indivPurchArr);
    sumPurchArr = sortSums(sumPurchArr);

    setShowTableData(indivPurchArr);
    setPrintTableData(sumPurchArr);

    let newTotal = 0;
    sumPurchArr.forEach((productsPurchased) => {
      let subt = productsPurchased.subtotal;
      newTotal += parseFloat(subt.replace("Rp ", "").replaceAll(",", ""))
    })
    setPurchaseTotal(`${formatCurrency(parseInt(newTotal))}`);
  }, [ purchasesData ]);

  useEffect(() => {
    setShowTableData((old) => { return sortIndivs(old); });
    setPrintTableData((old) => { return sortSums(old); });
  }, [ sortBy ])

  const printRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const [viewOpt, setViewOpt] = useState(0);

  // Add new states for product view
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchForm, setSearchForm] = useState({ productSearch: "" });

  // Setup search provider with modified search field
  const [
    searchResults,
    clearSearch,
    querySearch,
    keydownHandler
  ] = useSearchProvider(
    productData.map(product => ({
      ...product,
      // Add a searchable full name that includes packaging details
      full_name: `${product.p_name} (${product.qty_pack} ${product.unit} / ${product.packaging}) [${product.category}]`
    })),
    "full_name", // Change search field from p_name to full_name
    setSearchForm,
    "productSearch",
    5,
    true
  );

  // Function to handle search input changes
  function searchChangeHandler(evt) {
    setSearchForm(prev => ({
      ...prev,
      [evt.target.name]: evt.target.value
    }));
    querySearch(evt.target.value);
  }

  // Function to handle product selection
  useEffect(() => {
    if (searchForm.productSearch && typeof searchForm.productSearch === 'object') {
      setSelectedProduct(searchForm.productSearch);
      setSearchForm({ productSearch: "" });
    }
  }, [searchForm.productSearch]);

  // Function to clear selected product
  function clearProductView() {
    setSelectedProduct(null);
    setSearchForm({ productSearch: "" });
  }

  // Add new state for product-specific purchases
  const [productPurchases, setProductPurchases] = useState([]);

  // Add new fetch function for product purchases
  useFunctionsFetch(
    [selectedProduct],
    "/purchases/by-product",
    selectedProduct ? { pid: selectedProduct.pid } : null,
    optionsBinding["get-purchase-product"],
    selectedProduct ? 1 : 0,
    (res) => {
      let formattedPurchases = [];
      res.forEach((purchase) => {
        const product = purchase.purchasedProducts.find(p => p.pid === selectedProduct.pid);
        const productInfo = productData.find(p => p.pid === selectedProduct.pid);
        
        let shownQtyString;
        let remaining = product.std_pur_qty % productInfo.qty_pack;
        if (remaining === 0) {
          shownQtyString = `${product.std_pur_qty / productInfo.qty_pack} ${productInfo.packaging}`;
        } else {
          let intPackaging = Math.floor(product.std_pur_qty / productInfo.qty_pack);
          if (intPackaging === 0) {
            shownQtyString = `${remaining} ${productInfo.unit}`;
          } else {
            shownQtyString = `${intPackaging} ${productInfo.packaging} + ${remaining}`;
          }
        }

        formattedPurchases.push({
          id: product.pid,
          purch: purchase.pur_id,
          supplier: purchase.pur_supplier,
          shown_date: new Date(purchase.pur_date).toDateString().split(" ").slice(1).join(" "),
          shown_qty: shownQtyString,
          shown_price: `Rp ${formatCurrency(Math.round(product.std_pur_price * productInfo.qty_pack))} / ${productInfo.packaging}`,
          ed: product.expirydate,
          shown_subtotal: `Rp ${formatCurrency(Math.round(product.std_pur_price * product.std_pur_qty))}`
        });
      });
      setProductPurchases(formattedPurchases);
    }
  );

  // Update the getProductPurchases function
  const getProductPurchases = () => {
    if (!selectedProduct) return [];
    return productPurchases;
  };

  return (
    <div className="laporanPembelian">
      <h3 className="tabHeading">Laporan Pembelian</h3>
      <div className="controls">
        <form style={{ display: "flex" }}>
          <div>

            <div style={{ width: "200px" }}>
              <label htmlFor="start">Start <span className="reqStar">*</span></label><br />
              <input name="start" type="date" value={formData.start} onChange={formChangeHandler} /> 
            </div>
            <div style={{ width: "200px" }}>
              <label htmlFor="end">End <span className="reqStar">*</span></label><br />
              <input name="end" type="date" value={formData.end} onChange={formChangeHandler} /> 
            </div>
          </div>
        </form>
        <Button 
          className="cetakStockCheckBtn ms-auto"
          iconClasses="bi bi-list-check" 
          btnText="Pembelian Check" 
          clickHandler={handlePrint} 
          type="button" 
        />
      </div>
      <div style={{ 
        display: "flex", 
        padding: "2rem", 
        border: "1px solid var(--c-secondary)",
        borderRadius: "0.5rem",
        marginBottom: "1.5rem",
        fontSize: "1.5em",
      }}>
        <div>Total Pembelian</div>
        <div className="ms-auto">Rp {purchaseTotal}</div>
      </div>
      <TableTab selector={viewOpt} setSelector={setViewOpt} options={[
        {
          label: "Overview",
          trigger: 0
        },
        {
          label: "Purchase View",
          trigger: 1
        },
        {
          label: "Product View",
          trigger: 2
        }
      ]} />
      <div className="laporanDataDiv mw-100">
        {(viewOpt === 0) ?
          <div>
            <div className="d-flex align-items-center mb-4">
              <p className="dataTitle">Overview</p>
              <div className="sortDiv">
                Sort
                <Toggler options={[
                  ["ID", sortBy === 0, () => { setSortBy(0); }],
                  ["Produk", sortBy === 1, () => { setSortBy(1); }],
                  ["PID", sortBy === 2, () => { setSortBy(2); }],
                  ["Jumlah", sortBy === 3, () => { setSortBy(3); }],
                  ["Subtotal", sortBy === 4, () => { setSortBy(4); }],
                ]} style={{ fontSize:"18px", padding: "0.6em 1.5em" }} />
              </div>
            </div>
            <div className="x-scroll-wrapper">
              <Table
                format={[
                  ["ID", "id", "5%"], 
                  ["Produk", "name", "41.5%"],
                  ["PID", "purch", "4.5%"],
                  ["Tanggal", "shown_date", "9%"],
                  ["Jumlah", "shown_qty", "7%"],
                  ["Harga Beli (~)", "shown_price", "12%"],
                  ["ED Tercepat", "ed", "9%"],
                  ["Subtotal", "shown_subtotal", "12%"]
                ]} 
                dataArray={showTableData}
                dataOnly
                xScroll
                yScroll
              />
            </div>
          </div> 
        : (viewOpt === 1) ?
          <div>
            hi
          </div>
        : (
          <div>
            <div className="productView">
              <div className="d-flex align-items-center mb-4">
                <p className="dataTitle">
                  Product Details {selectedProduct ? `— ${selectedProduct.p_name}` : ""}
                </p>
                <Button
                  className="ms-auto"
                  iconClasses="bi bi-x-lg"
                  btnText="Clear"
                  clickHandler={clearProductView}
                  type="button"
                />
              </div>
              {!selectedProduct ? (
                <div className="searchWrapper">
                  <input
                    autoComplete="off"
                    type="text"
                    name="productSearch"
                    value={searchForm.productSearch}
                    onChange={searchChangeHandler}
                    onKeyDown={keydownHandler}
                    onFocus={() => {
                      console.log(searchForm.productSearch);
                      querySearch(searchForm.productSearch);
                    }}
                    onBlur={clearSearch}
                    placeholder="Search Product by Name"
                    className="searchInput"
                  />
                  {searchResults.length > 0 ? <div className="searchContainer">
                    {searchResults}
                  </div> : ""}
                </div>
              ) : (
                <>
                  <div className="x-scroll-wrapper">
                    <Table
                      format={[
                        ["PID", "purch", "8%"],
                        ["Supplier", "supplier", "30%"],
                        ["Tanggal", "shown_date", "12%"],
                        ["Jumlah", "shown_qty", "10%"],
                        ["Harga Beli (~)", "shown_price", "13%"],
                        ["ED", "ed", "10%"],
                        ["Subtotal", "shown_subtotal", "17%"]
                      ]}
                      dataArray={getProductPurchases()}
                      dataOnly
                      xScroll
                      yScroll
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </div>
      {/* Print Area */}
      <div style={{ display: "none" }}>
        <div  className="printContainer" style={{ padding: "2em" }} ref={printRef}>
          <h3 className="tabHeading" style={{ marginTop: "0.35em", marginBottom: "0.75em" }}>Laporan Pembelian</h3>
          <Table
            format={[
              ["ID", "id", "8%"], 
              ["Produk", "name", "57%"],
              ["Jumlah", "shown_qty", "17%"],
              ["ED Tercepat", "ed", "18%"]
            ]} 
            dataArray={printTableData}
          />
        </div>
      </div>
    </div>
  )
}