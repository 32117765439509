import Table from "../../Utilities/Table/table";
import { UserContext, OptionsContext } from "../../Contexts";
import { useFunctionsFetch } from "../../UtilFunctions";
import { useContext, useEffect, useState, useRef } from "react";
import { formatCurrency } from "../../UtilFunctions";
import { useReactToPrint } from "react-to-print";
import Button from "../../Utilities/Button/button";
import Toggler from "../../Utilities/Toggler/toggler";

import "./laporanpenjualan.css";
import TableTab from "../../Utilities/TableTab/tabletab";

const banUsernames = ["yuyanto"];
const banStart = ["2024-10-01"];
const banEnd = ["2024-11-01"];

export default function LaporanPenjualan(props) {
  const optionsBinding = useContext(OptionsContext);
  const [user] = useContext(UserContext);

  // Form Implementation
  let startMonthDate = (new Date(new Date().getFullYear(), new Date().getMonth(), 1)).toLocaleDateString().split("/");
  let endMonthDate = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).toLocaleDateString().split("/");
  startMonthDate[0] = startMonthDate[0].padStart(2, "0");
  startMonthDate[1] = startMonthDate[1].padStart(2, "0");
  endMonthDate[0] = endMonthDate[0].padStart(2, "0");
  endMonthDate[1] = endMonthDate[1].padStart(2, "0");

  let datesInitial = {
    start: `${startMonthDate[2]}-${startMonthDate[0]}-${startMonthDate[1]}`,
    end: `${endMonthDate[2]}-${endMonthDate[0]}-${endMonthDate[1]}`
  }

  if (banUsernames.includes(user.username)) {
    datesInitial.start = banStart;
    datesInitial.end = banEnd;
  }

  const [formData, setFormData] = useState(datesInitial)


  function formChangeHandler(arg) {
    if (
      banUsernames.includes(user.username) 
      && (arg.target.name == "start" || arg.target.name == "end")
      && arg.target.value > banEnd
    ) {
      setFormData((prev) => ({
        ...prev,
        [arg.target.name]: banEnd
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [arg.target.name]: arg.target.value
      }));
    }
    setCallSyncSales(1);
  }

  const [productData, setProductData] = useState([]);

  useFunctionsFetch(
    [],
    "/products/list",
    {},
    optionsBinding["report-sale"],
    true,
    (res) => {
      setProductData(res);
      setCallSyncSales(1);
    }
  )

  const [callSyncSales, setCallSyncSales] = useState(0);
  const [salesData, setSalesData] = useState([]);
  const [saleTableData, setSaleTableData] = useState([]);
  const [printSaleTable, setPrintSaleTable] = useState([]);
  const [totalFigures, setTotalFigures] = useState([0, 0]);
  const [sortBy, setSortBy] = useState(3);
  const [clearance, setClearance] = useState(1);

  useFunctionsFetch(
    [],
    "/sales/list",
    { 
      start: String(formData.start),
      end: String(formData.end)
    },
    optionsBinding["report-sales"],
    callSyncSales,
    (res) => {
      setCallSyncSales(0);
      setSalesData(res.data);
      if (clearance != res.clearance) setClearance(res.clearance)
    }
  )

  function getSortedArr(unsorted) {
    unsorted = unsorted.sort((a, b) => {
      if (a.name < b.name) { return -1; }
      else if (a.name > b.name) { return 1; }
      return 0;
    })
    return unsorted.sort((a, b) => {
      if (sortBy === 0) {
        if (a.id < b.id) { return -1; }
        else if (a.id > b.id) { return 1; }
        else return 0;
      } else if (sortBy === 1) {
        if (a.name < b.name) { return -1; }
        else if (a.name > b.name) { return 1; }
        else return 0;
      } else if (sortBy === 2) {
        if (a.qty < b.qty) { return 1; }
        else if (a.qty > b.qty) { return -1; }
        else return 0;
      } else if (sortBy === 3) {
        if (a.sid < b.sid) { return 1; }
        else if (a.sid > b.sid) { return -1; }
        else return 0;
      } else if (sortBy === 4) {
        if (a.subtotal < b.subtotal) { return 1; }
        else if (a.subtotal > b.subtotal) { return -1; }
        else return 0;
      }
      return 0;
    })
  }

  useEffect(() => {
    let indivPurchArr = [];
    let sumPurchArr = [];
    let disc = 0;
    let profit = 0;
    let retur = 0;

    salesData.forEach((sale) => {
      profit += sale.profit;
      let saleFactor = (sale.typ === 3) ? -1 : 1;
      if (sale.typ === 1) disc -= sale.disc; else disc += sale.disc;

      sale.sales.forEach((product) => {
        let productInfo = productData.filter((prod) => (prod.pid === product.pid))[0];
        let shownQtyString;
        let remaining = product.std_sale_quantity % productInfo.qty_pack;
        if (remaining === 0) 
          shownQtyString = `${(saleFactor === -1) ? '-' : ''}${product.std_sale_quantity / productInfo.qty_pack} ${productInfo.packaging}`;
        else {
          let intPackaging = Math.floor(product.std_sale_quantity / productInfo.qty_pack);
          if (intPackaging === 0) shownQtyString = `${(saleFactor === -1) ? '-' : ''}${remaining} ${productInfo.unit}`;
          else shownQtyString = `${(saleFactor === -1) ? '-' : ''}${intPackaging} ${productInfo.packaging} + ${remaining}`;
        };

        // Add to Displayed Array
        if (saleFactor === -1) {
          retur += product.price * product.std_sale_quantity * saleFactor
        }
        indivPurchArr.push({
          id: product.pid,
          name: `${productInfo.p_name} (${productInfo.qty_pack} ${productInfo.unit} / ${productInfo.packaging})`,
          ed: product.earliest_ed,
          price: `Rp ${formatCurrency(product.price * productInfo.qty_pack)}`,
          shown_price: `Rp ${formatCurrency(Math.round(product.price * productInfo.qty_pack))} / ${productInfo.packaging}`,
          qty: (product.std_sale_quantity * saleFactor) / productInfo.qty_pack,
          shown_qty: shownQtyString,
          subtotal: product.price * product.std_sale_quantity * saleFactor,
          shown_subtotal: `Rp ${formatCurrency(Math.round(product.price * product.std_sale_quantity * saleFactor))}`,
          shown_date: new Date(sale.date).toDateString().split(" ").slice(1).join(" "),
          sid: sale.sid,
          rem_qty: (productInfo.rem_stocks.length > 0) ? 
          (() => {
            let totalStock = productInfo.rem_stocks.map((stock) => (stock.remaining_qty));
            totalStock = totalStock.reduce((prev, current) => prev + current, 0);
            let remaining = totalStock % productInfo.qty_pack
            if (remaining === 0) return `${totalStock / productInfo.qty_pack} ${productInfo.packaging}`;
            else {
              let intPackaging = Math.floor(totalStock / productInfo.qty_pack);
              if (intPackaging === 0) return `${remaining} ${productInfo.unit}`;
              else return `${intPackaging} ${productInfo.packaging} + ${remaining}`;
            }
          })()
          : `0 ${productInfo.packaging}`
        })

        // Logic for Printed Array
        if (sumPurchArr.filter((prod) => (prod.id === product.pid)).length === 0) {
          sumPurchArr.push({
            id: product.pid,
            name: `${productInfo.p_name} (${productInfo.qty_pack} ${productInfo.unit} / ${productInfo.packaging})`,
            ed: product.earliest_ed,
            price: `Rp ${formatCurrency(product.price * productInfo.qty_pack)}`,
            shown_price: `Rp ${formatCurrency(Math.round(product.price * productInfo.qty_pack))} / ${productInfo.packaging}`,
            qty: (product.std_sale_quantity * saleFactor),
            shown_qty: shownQtyString,
            subtotal: `Rp ${formatCurrency(product.price * product.std_sale_quantity * saleFactor)}`,
            shown_subtotal: `Rp ${formatCurrency(Math.round(product.price * product.std_sale_quantity * saleFactor))}`
          })
        } else {
          let eIdx = sumPurchArr.findIndex((prod) => (prod.id === product.pid));
          let pData = sumPurchArr[eIdx];

          sumPurchArr[eIdx].price = 
            `Rp ${formatCurrency(((parseFloat((pData.price).replace("Rp ", "").replaceAll(",", "")) * pData.qty) + (product.price * product.std_sale_quantity))
            / ((pData.qty * productInfo.qty_pack) + product.std_sale_quantity) * productInfo.qty_pack)}`
          sumPurchArr[eIdx].qty = pData.qty + (product.std_sale_quantity * saleFactor);
          sumPurchArr[eIdx].subtotal = `Rp ${formatCurrency(parseFloat((pData.subtotal).replace("Rp ", "").replaceAll(",", "")) + (product.price * product.std_sale_quantity * saleFactor))}`;
          sumPurchArr[eIdx].shown_price = `Rp ${formatCurrency(Math.round(parseFloat(sumPurchArr[eIdx].price.replace("Rp ", "").replaceAll(",", ""))))} / ${productInfo.packaging}`;
          sumPurchArr[eIdx].shown_subtotal = `Rp ${formatCurrency(Math.round(parseFloat(sumPurchArr[eIdx].subtotal.replace("Rp ", "").replaceAll(",", ""))))}`;
          let remaining = pData.qty % productInfo.qty_pack;
          if (remaining === 0) shownQtyString = `${pData.qty / productInfo.qty_pack} ${productInfo.packaging}`;
          else {
            let intPackaging = Math.floor(pData.qty / productInfo.qty_pack);
            if (intPackaging === 0) shownQtyString = `${remaining} ${productInfo.unit}`;
            else shownQtyString = `${intPackaging} ${productInfo.packaging} + ${remaining}`;
          };
          sumPurchArr[eIdx].shown_qty = shownQtyString;
        }

      })
    })

    sumPurchArr = getSortedArr(sumPurchArr);
    indivPurchArr = getSortedArr(indivPurchArr);

    setSaleTableData(indivPurchArr);
    sumPurchArr = sumPurchArr.filter((listing) => listing.qty != 0);
    setPrintSaleTable(sumPurchArr);

    let newTotal = 0;

    sumPurchArr.forEach((productSale) => {
      let subt = productSale.subtotal;
      newTotal += Math.round(subt.replace("Rp ", "").replaceAll(",", ""))
    })
    setTotalFigures([newTotal, disc, profit, retur]);
  }, [ salesData ]);

  useEffect(() => {
    setSaleTableData((old) => [ ...getSortedArr(old) ]);
  }, [ sortBy ])

  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const [viewOpt, setViewOpt] = useState(0);

  return (
    <div className="laporanPenjualan">
      <h3 className="tabHeading">Laporan Penjualan</h3>
      <div className="controls">
        <form style={{ display: "flex" }}>
          <div style={{ width: "200px" }}>
            <label htmlFor="start">Start <span className="reqStar">*</span></label><br />
            <input name="start" type="date" value={formData.start} onChange={formChangeHandler} /> 
          </div>
          <div style={{ width: "200px" }}>
            <label htmlFor="end">End <span className="reqStar">*</span></label><br />
            <input name="end" type="date" value={formData.end} onChange={formChangeHandler} /> 
          </div>
        </form>
        <Button 
          className="cetakStockCheckBtn ms-auto mt-0"
          iconClasses="bi bi-list-check" 
          btnText="Penjualan Check" 
          clickHandler={handlePrint} 
          type="button" 
        />
      </div>
      <div style={{ 
        padding: "2rem", 
        border: "1px solid var(--c-secondary)",
        borderRadius: "0.5rem",
        marginBottom: "1.5rem",
        fontSize: "1.5em",
        display: clearance === 2 ? "block" : "none"
      }}>
        <div className="d-flex mb-3">
          <div>Net Sales</div>
          <div className="ms-auto">Rp {formatCurrency(parseInt(totalFigures[0] + parseInt(totalFigures[1])))}</div>
        </div>
        <div className="d-flex mb-2" style={{ fontSize: "18px" }}>
          <div>Gross Sales</div>
          <div className="ms-auto">Rp {formatCurrency(parseInt(totalFigures[0]) - parseInt(totalFigures[3]))}</div>
        </div>
        <div className="d-flex mb-2" style={{ fontSize: "18px" }}>
          <div>Returns</div>
          <div className="ms-auto">Rp {formatCurrency(parseInt(totalFigures[3]))}</div>
        </div>
        <div className="d-flex mb-4" style={{ fontSize: "18px" }}>
          <div>Margin ( Discount )</div>
          <div className="ms-auto">Rp {formatCurrency(parseInt(totalFigures[1]))}</div>
        </div>
        <div className="d-flex">
          <div>Profit ( Loss )</div>
          <div className="ms-auto">Rp {formatCurrency(parseInt(totalFigures[2]))}</div>
        </div>
      </div>
      <TableTab selector={viewOpt} setSelector={setViewOpt} options={clearance === 2 ? [
        {
          label: "Product View",
          trigger: 0
        },
        {
          label: "Sale View",
          trigger: 1
        }
      ] : [
        {
          label: "Product View",
          trigger: 0
        }
      ]} />
      <div className="laporanDataDiv mw-100">
        {(viewOpt === 0) ?
          <div>
            <div className="d-flex align-items-center mb-4">
              <p className="dataTitle">Product View</p>
              <div className="sortDiv">
                Sort
                <Toggler options={clearance === 2 ? [
                  ["ID", sortBy === 0, () => { setSortBy(0); }],
                  ["Produk", sortBy === 1, () => { setSortBy(1); }],
                  ["SID", sortBy === 3, () => { setSortBy(3); }],
                  ["Jumlah", sortBy === 2, () => { setSortBy(2); }],
                  ["Subtotal", sortBy === 4, () => { setSortBy(4); }]
                ] : [
                  ["ID", sortBy === 0, () => { setSortBy(0); }],
                  ["Produk", sortBy === 1, () => { setSortBy(1); }],
                  ["SID", sortBy === 3, () => { setSortBy(3); }],
                  ["Jumlah", sortBy === 2, () => { setSortBy(2); }]
                ]} style={{ fontSize:"18px", padding: "0.6em 1.5em" }} />
              </div>
            </div>
            <div className="x-scroll-wrapper">
              <Table
                format={clearance === 2 ? [
                  ["ID", "id", "5%"], 
                  ["Produk", "name", "43%"],
                  ["SID", "sid", "4.5%"],
                  ["Tanggal", "shown_date", "8.5%"],
                  ["ED Tercepat", "ed", "9%"],
                  ["Jumlah", "shown_qty", "6.5%"],
                  ["Sisa", "rem_qty", "7.5%"],
                  ["Harga Jual (~)", "shown_price", "12%"],
                  ["Subtotal", "shown_subtotal", "12%"],
                ] : [
                  ["ID", "id", "5%"], 
                  ["Produk", "name", "50%"],
                  ["SID", "sid", "8%"],
                  ["Tanggal", "shown_date", "12%"],
                  ["Jumlah", "shown_qty", "10%"],
                  ["ED Tercepat", "ed", "15%"]
                ]} 
                dataArray={saleTableData}
                dataOnly
                xScroll
                yScroll
              />
            </div>
          </div> :
          <div>
            hi
          </div>
        }
      </div>
      <div style={{ display: "none" }}>
        <div  className="printContainer" style={{ padding: "2em" }} ref={printRef}>
          <h3 className="tabHeading" style={{ marginTop: "0.35em", marginBottom: "0.75em" }}>Laporan Penjualan</h3>
          <Table
            format={[
              ["ID", "id", "8%"], 
              ["Produk", "name", "57%"],
              ["Jumlah", "shown_qty", "17%"],
              ["ED Tercepat", "ed", "18%"]
            ]} 
            dataArray={printSaleTable}
          />
        </div>
      </div>
    </div>
  )
}