import { useContext, useEffect, useState } from "react"
import { MinuteContext, OptionsContext, UserContext } from "../../Contexts";
import useSearchProvider from "../../Utilities/Search/search"
import { formatCurrency, useFunctionsFetch } from "../../UtilFunctions";
import Button from "../../Utilities/Button/button";
import OperationMessage from "../../Utilities/OperationMessage/operationmessage";
import PopupWindow from "../../Utilities/PopupWindow/popupwindow";
import "../../Utilities/Table/table.css"
import TableRow from "../../Utilities/Table/tablerow";
import "./kasir.css"
import TambahCustomer from "../Customer/tambahcustomer";
import Toggler from "../../Utilities/Toggler/toggler";

export default function Kasir(props) {
  const [currentUser,,] = useContext(UserContext);
  const optionsBinding = useContext(OptionsContext);
  const minute = useContext(MinuteContext);
  let [tdate, tmonth, tyear, nhours, nmins] = [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear(), new Date().getHours(), new Date().getMinutes()];

  // Block Display
  const [loadProgress, setLoadProgress] = useState(0)
  const [blockDisplay, setBlockDisplay] = useState(true)
  useEffect(() => {
    if (loadProgress >= 4) {
      setBlockDisplay(false);
    }
  }, [ loadProgress ])

  // Print Config Prep
  const [printConfig, setPrintConfig] = useState(null);
  useFunctionsFetch(
    null,
    "/system/print-config",
    {},
    optionsBinding["kasir"],
    true,
    (res) => {
      setPrintConfig(res);
      setLoadProgress((prev) => prev + 1)
    } 
  )

  // Active Popup Controller State
  const [currentPopup, setCurrentPopup] = useState(null);

  // Sync Sale ID
  const [callSyncSaleCount, setCallSyncSaleCount] = useState(1)
  useFunctionsFetch(
    null, 
    "/system/persistent-data/accumulatedData/totalSalesCount", 
    {}, 
    optionsBinding["kasir"], 
    callSyncSaleCount, 
    (res) => {
      setPenjualanData((prev) => ({
        ...prev,
        sale_id: res + 1
      }))
      setCallSyncSaleCount((prev) => { return prev - 1; })
      setLoadProgress((prev) => prev + 1)
    }
  )

  // Penjualan Form Functionality
  const [allowTimeChange, setAllowTimeChange] = useState(
    props.saveData.kasir ? props.saveData.kasir.allowTimeChange : true
  );
  const penjualanDataBlank = {
    sale_id: 0,
    sale_customer: "",
    sale_date: 
    `${tyear}-${`${tmonth}`.length > 1 ? tmonth : `0${tmonth}`}-${`${tdate}`.length > 1 ? tdate : `0${tdate}`}T${`${nhours}`.length > 1 ? nhours : `0${nhours}`}:${`${nmins}`.length > 1 ? nmins : `0${nmins}`}`,
    sale_payment: "",
    sale_cust_verify: undefined,
    sale_cust_typ: undefined,
    special_discount: "",
    sale_typ: props.saveData.kasir ? props.saveData.kasir.penjualanData.sale_typ : 1
  }

  const [penjualanData, setPenjualanData] = useState({ ...penjualanDataBlank });

  useEffect(() => {
    if (allowTimeChange) {
      [tdate, tmonth, tyear, nhours, nmins] = [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear(), new Date().getHours(), new Date().getMinutes()];
  
      setPenjualanData((prev) => ({
        ...prev,
        sale_date: `${tyear}-${`${tmonth}`.length > 1 ? tmonth : `0${tmonth}`}-${`${tdate}`.length > 1 ? tdate : `0${tdate}`}T${`${nhours}`.length > 1 ? nhours : `0${nhours}`}:${`${nmins}`.length > 1 ? nmins : `0${nmins}`}`
      }))
    }
  }, [ minute ]);

  function handlePenjualanFormChange(changeEvt) {
    if (changeEvt.target.name === "sale_customer") {
      if (!/^(0[\d]{0,14})?$/.test(changeEvt.target.value)) return;
      setPenjualanData((prev) => ({
        ...prev,
        sale_cust_verify: undefined,
        sale_cust_typ: undefined
      }))
      setSaleTableData((prevTableData) => {
        return prevTableData.map((prod) => ({
          ...prod,
          product_formatsaleprice: ""
        }))
      })
    }

    if (changeEvt.target.name === "special_discount") {
      if (changeEvt.type && (changeEvt.type === "check") && (changeEvt.target.value.includes("-"))) {
        setPenjualanData((prevData) => ({
          ...prevData,
          [changeEvt.target.name]: ""
        }));
        return;
      }
      
      // For online orders (Halodoc/GoApotik/Tokopedia/Shopee), allow negative numbers for adjustments
      if (([4, 5, 6, 7].includes(penjualanData.sale_typ)) && (!/^-?([1-9][\d]*)?$/.test(changeEvt.target.value.replaceAll(',', '')))) return 
      // For standard/racikan/retur orders, only allow positive numbers for discounts
      else if (([1, 2, 3].includes(penjualanData.sale_typ)) && (!/^([1-9][\d]*)?$/.test(changeEvt.target.value.replaceAll(',', '')))) return;

      let saleTotalValue = undefined;
    
      for (let rowIndex = 0; rowIndex < saleTableData.length; rowIndex++) {
        let nominal = parseInt(saleTableData[rowIndex].product_subtotal.replace("Rp ", "").replaceAll(",", ""));
        if (isNaN(nominal) !== true) { 
          if (saleTotalValue === undefined) saleTotalValue = 0;
          saleTotalValue += nominal; 
        }
      }
      
      if (saleTotalValue === undefined) return;

      // For standard and return transactions, prevent discount from exceeding total sale value
      if (([1, 3].includes(penjualanData.sale_typ)) && (saleTotalValue <= parseInt(changeEvt.target.value.replaceAll(',', '')))) {
        setPenjualanData((prevData) => ({
          ...prevData,
          [changeEvt.target.name]: formatCurrency(saleTotalValue)
        }));
        return;
      }
      // For online transactions (Halodoc/GoApotik/Tokopedia/Shopee), prevent negative adjustment from making total go below 0
      if (([4, 5, 6, 7].includes(penjualanData.sale_typ)) && (saleTotalValue + parseInt(changeEvt.target.value.replaceAll(',', '')) < 0)) {
        setPenjualanData((prevData) => ({
          ...prevData,
          [changeEvt.target.name]: formatCurrency(saleTotalValue * -1)
        }));
        return;
      }

      setPenjualanData((prevData) => ({
        ...prevData,
        [changeEvt.target.name]: formatCurrency(changeEvt.target.value.replaceAll(',', ''))
      }));

      return;
    }

    setPenjualanData((prevData) => ({
      ...prevData,
      [changeEvt.target.name]: changeEvt.target.value
    }));

    if (changeEvt.target.name === "sale_payment") queryPembayaranSearch(changeEvt.target.value);
    if (changeEvt.target.name === "sale_date") setAllowTimeChange(false);

  }

  // Search Sale Unit
  const [indivProductFormats, setIndivProductFormats] = useState([])
  const [callShowProductFormats, setCallShowProductFormats] = useState(false)
  const [formatsSearch, clearFormatsSearch, queryFormatsSearch, formatsKeyDownHandler] = useSearchProvider(
    indivProductFormats.map((format) => ({ format: format })),
    "format", 
    (setterF) => {
      let newProductData = setterF(saleTableData[indivProductIndex])
      setSaleTableData((prev) => {
        let newArray = [ ...prev ];
        newArray[indivProductIndex] = newProductData;
        return newArray
      })
      refreshSubtotals();
      enforceMaxQty(indivProductIndex, saleTableData[indivProductIndex].sale_quantity, newProductData);
    },
    "sale_format"
  )

  useEffect(() => {
    if (callShowProductFormats === true) {
      queryFormatsSearch("")
      setCallShowProductFormats(false)
    }
  }, [ indivProductFormats ])

  // Search Product Function
  const [saleTableData, setSaleTableData] = useState([])      // Container for Table Product Info
  const [productsData, setProductsData] = useState([])
  const [indivProductIndex, setIndivProductIndex] = useState(0)
  const [warnQtyMessage, setWarnQtyMessage] = useState(null);
  const [productsSearch, clearProductsSearch, queryProductsSearch, productsKeyDownHandler] = useSearchProvider(
    productsData.map((info) => {
      return {
        ...info,
        product_format_name: `${info.p_name} (${info.qty_pack} ${info.unit} / ${info.packaging})`
      }
    }).filter((prod) => {
      // Prevent Duplicate Listing
      return !(saleTableData.map((items) => items.product_format_name).includes(prod.product_format_name))
    }).filter((prod) => { 
      // Only allow zero stock items for retur
      return ((penjualanData.sale_typ === 3) ? true : prod.rem_stocks.length > 0); 
    }), 
    "product_format_name",
    (setterF) => {
      let newProductData = setterF(saleTableData[indivProductIndex]);
      let accuStock = newProductData.product_data.rem_stocks.reduce((prev, cur) => prev + cur.remaining_qty, 0);
      newProductData.product_format_name = newProductData.product_data.product_format_name;

      // Only allow more than stock for retur
      if (penjualanData.sale_typ !== 3) {
        newProductData.product_ed = newProductData.product_data.rem_stocks[0].expirydate;
        if (accuStock >= newProductData.product_data.qty_pack) 
          newProductData.sale_format = newProductData.product_data.packaging;
        else {
          newProductData.sale_format = newProductData.product_data.unit;
          setWarnQtyMessage(`Sisa jumlah stok '${newProductData.product_format_name}' adalah ${accuStock} ${newProductData.product_data.unit} dan tidak cukup untuk penjualan 1 ${newProductData.product_data.packaging} (${newProductData.product_data.qty_pack} ${newProductData.product_data.unit} / ${newProductData.product_data.packaging}).`);
        }
      }

      newProductData.product_formatsaleprice = 
        (penjualanData.sale_cust_verify === true) ? 
        (penjualanData.sale_cust_typ === "v") ? 
        (newProductData.product_data.s_price_b) ?
        formatCurrency(newProductData.product_data.s_price_b) : 
        formatCurrency(newProductData.product_data.s_price) : 
        formatCurrency(newProductData.product_data.s_price) : "";

      newProductData.sale_quantity = "1";

      setSaleTableData((prev) => {
        let newArray = [ ...prev ];
        newArray[indivProductIndex] = newProductData;
        return newArray;
      })
      refreshSubtotals();
    }, 
    "product_data", 
    5,
    true
  );

  const [callUpdateProducts, setCallUpdateProducts] = useState(true);
  useFunctionsFetch(
    undefined, 
    "/products/list", 
    { query: { enabled: true } }, 
    optionsBinding["kasir"], 
    callUpdateProducts, 
    (res) => {
      setProductsData(res)
      setCallUpdateProducts(false);
      setLoadProgress((prev) => prev + 1)
    }
  )

  // Penjualan Array Functionality
  const [maxQtyMessage, setMaxQtyMessage] = useState(null)

  function enforceMaxQty(itemIdx, attemptQty, updatedData = undefined) {
    if (penjualanData.sale_typ === 3) return false; // don't enforce max qty for retur

    let indivProduct;
    if (updatedData !== undefined) {
      indivProduct = { ...updatedData };
    } else indivProduct = { ...saleTableData[itemIdx] };

    if (indivProduct.product_data) {
      let rem_stock = (indivProduct.product_data.rem_stocks.reduce((prev, current) => {
        return prev + current.remaining_qty
      }, 0));

      let remainder = undefined;
      if (indivProduct.sale_format === indivProduct.product_data.packaging) {
        if ((rem_stock % indivProduct.product_data.qty_pack) !== 0) {
          remainder = rem_stock % indivProduct.product_data.qty_pack;
        }
        rem_stock = Math.floor(rem_stock / indivProduct.product_data.qty_pack);
      }

      if (parseFloat(attemptQty) > rem_stock) {
        setSaleTableData((prevTableData) => {
          let newTableData = [ ...prevTableData ];
          newTableData[itemIdx].sale_quantity = `${rem_stock}`;
    
          return newTableData;
        });
        refreshSubtotals();
        setMaxQtyMessage(`Sisa jumlah stok '${indivProduct.product_format_name}' adalah ${(rem_stock > 0) ? `${rem_stock} ${indivProduct.sale_format}` : ""}${remainder ? `${(rem_stock > 0) ? ' dan' : ''} ${remainder} ${indivProduct.product_data.unit}` : ""}.`)
        return true;
      } else return false;
    }
  }

  function handleTableDataChange(changeEvt, productIndex) {
    if (changeEvt.target.name === "sale_quantity") { 
      if (!(/^([1-9][\d]*)?$/.test(changeEvt.target.value))) return;
      let escapeChange = enforceMaxQty(
        productIndex,
        changeEvt.target.value
      );
      if (escapeChange) return;
    }

    setSaleTableData((prevTableData) => {
      let newTableData = [ ...prevTableData ];
      newTableData[productIndex][changeEvt.target.name] = changeEvt.target.value;

      if (changeEvt.target.name === "product_format_name") {
        newTableData[productIndex].sale_format = ""
        newTableData[productIndex].product_ed = ""
        newTableData[productIndex].product_formatsaleprice = ""
        if (newTableData[productIndex].product_data) delete newTableData[productIndex].product_data
        queryProductsSearch(changeEvt.target.value)
      }

      return newTableData;
    });

    if (changeEvt.target.name === "sale_quantity") refreshSubtotals();

  }

  function refreshSubtotals() {
    if (saleTableData.length > 0) {
      setSaleTableData((prev) => {
        let newTableData = [ ...prev ];
        newTableData = newTableData.map((prod) => {
          let newSubtotal = 
            (prod.product_data && prod.product_formatsaleprice) ? 
            `Rp ${formatCurrency(
              Math.round((
                parseInt(
                penjualanData.sale_cust_typ ? 
                penjualanData.sale_cust_typ === "v" ? 
                prod.product_data.s_price_b ?
                prod.product_data.s_price_b : 
                prod.product_data.s_price : 
                prod.product_data.s_price : ""
                ) / prod.product_data.qty_pack
              ) * (
                prod.sale_format === prod.product_data.packaging ? 
                (parseInt(prod.sale_quantity) * prod.product_data.qty_pack) : 
                parseInt(prod.sale_quantity)
              ))
            )}` : "Rp —";
          return {
            ...prod,
            product_subtotal: (newSubtotal !== "Rp NaN") ? newSubtotal : "Rp —",
            product_formatsaleprice: 
              (penjualanData.sale_cust_typ && prod.product_data) ? 
              formatCurrency(
                Math.floor(( penjualanData.sale_cust_typ ? 
                  penjualanData.sale_cust_typ === "v" ? 
                  prod.product_data.s_price_b ?
                  prod.product_data.s_price_b :
                  prod.product_data.s_price : 
                  prod.product_data.s_price : ""
                ) / (
                  prod.sale_format === prod.product_data.packaging ? 
                  1 : prod.product_data.qty_pack
                ) * 10) / 10
              ) 
              : ""
          }
        })
        return newTableData;
      })
    }
  }

  useEffect(() => {
    refreshSubtotals();
  }, [ penjualanData.sale_cust_typ ])

  function addNewProductToList() {
    setSaleTableData((prevData) => {
      let newArray = [ ...prevData ];
      newArray.push({
        product_format_name: "",
        product_ed: "",
        product_formatsaleprice: "",
        sale_quantity: "",
        sale_format: "",
        product_subtotal: "Rp -"
      })
      return newArray
    })
  }

  function removeTableProduct(index) {
    setSaleTableData((prev) => { return prev.filter((row, idx) => {return idx !== index})})
  }

  let shownRows = saleTableData.map((data, idx) => 
    <TableRow key={idx} columns={[
      ["3.5%", idx + 1
      ], ["34.5%",
        <div style={{ position: "relative", paddingRight: "10%" }} className="editable">
          <input
            autoComplete="off"
            type="text"
            className="tableProductInput"
            placeholder="Produk"
            onChange={(changeEvt) => { handleTableDataChange(changeEvt, idx); }}
            name="product_format_name"
            value={data.product_format_name}
            onFocus={() => {
              setIndivProductIndex(idx);
              queryProductsSearch(saleTableData[idx]["product_format_name"]);
            }}
            onBlur={() => {clearProductsSearch()}}
            onKeyDown={productsKeyDownHandler}
          />
          {((productsSearch.length > 0) && (indivProductIndex === idx)) ? 
            <div className={`products searchContainer`}>
              {productsSearch}
            </div> 
          : ""}
        </div>
      ], ["13.5%", 
        <div className={(penjualanData.sale_typ === 3) ? "editable" : ""}>
          <input
            autoComplete="off"
            type="date"
            className="tableProductInput"
            onChange={(changeEvt) => { handleTableDataChange(changeEvt, idx); }}
            readOnly={(penjualanData.sale_typ === 3) ? false : true}
            style={{ width: "calc(100% - 10px)", flexGrow: "0" }}
            name="product_ed"
            value={data.product_ed}
          />
        </div>
      ], ["12%", 
        <>
          <span style={{ position: "absolute", zIndex: "-1" }} key={`${Math.random()}`.slice(2, -1)}>Rp</span>
          <input
            autoComplete="off"
            type="text"
            className="tableProductInput"
            placeholder="—"
            onChange={(changeEvt) => { handleTableDataChange(changeEvt, idx); }}
            readOnly={true}
            style={{ padding: "0.5em 0 0.5em 2em", backgroundColor: "transparent", cursor: "inherit" }}
            value={data.product_formatsaleprice}
          />
        </>
      ], ["2%", "x"
      ], ["5.5%", 
        <div className="editable">
          <input
            autoComplete="off"
            type="text"
            className="tableProductInput"
            placeholder="Qty"
            onChange={(changeEvt) => { handleTableDataChange(changeEvt, idx); }}
            name="sale_quantity"
            value={data.sale_quantity}
          />
        </div>
      ], ["10%", 
        <div style={{ position: "relative" }} className="selectable">
        <input
          autoComplete="off"
          type="text"
          className="tableProductInput"
          placeholder="Unit"
          onChange={(changeEvt) => { queryFormatsSearch("") }}
          name="sale_format"
          value={data.sale_format}
          readOnly={true}
          style={{ cursor: "pointer" }}
          onFocus={() => {
            if (saleTableData[idx].product_data) {
              setIndivProductIndex(idx);
              setIndivProductFormats([
                saleTableData[idx].product_data.unit, 
                saleTableData[idx].product_data.packaging
              ])
              setCallShowProductFormats(true);
            }
          }}
          onBlur={() => {clearFormatsSearch()}}
          onKeyDown={formatsKeyDownHandler}
        />
        {((formatsSearch.length > 0) && (indivProductIndex === idx)) ? 
          <div className={`formats searchContainer`}>
            {formatsSearch}
          </div> 
        : ""}
      </div>
      ], ["2%", "="
      ], ["13%", data.product_subtotal
      ], ["4%", 
        <button 
          style={{
            backgroundColor: "#ef0000", 
            color: "white",
            fontSize: "16px",
            lineHeight: "32px",
            height: "32px",
            borderRadius: "0.2em", 
            maxWidth: "32px",
            marginLeft: "auto",
            marginRight: "1px",
            cursor: "pointer",
            border: "none"
          }}
          onClick={() => { removeTableProduct(idx) }}
        >X</button>
      ]
    ]} />
  )

  shownRows.push(      
    <div key={"addProductBtn"}>
      <button
        style={{ 
          fontSize: "16px", 
          border: "1px solid var(--c-secondary)", 
          backgroundColor: "inherit", 
          cursor: "pointer", 
          padding: "0.8em 1em",
          width: "100%",
          borderRadius: "0.4em",
          margin: "2.5em 0",
          backgroundColor: "#eee",
          color: "black"
        }}
        onClick={addNewProductToList}
        key={`addProductButtonKey`}
      >
        <i className="bi bi-plus-square" style={{ marginRight: "0.5em" }} ></i> Tambah Produk
      </button>
    </div>
  );

  // Initialize variables to track total sale amount
  let saleTotalValue = undefined;
  let saleTotal = "Rp -";

  // Calculate total by summing up subtotals of all products
  for (let rowIndex = 0; rowIndex < saleTableData.length; rowIndex++) {
    // Parse numeric value from formatted currency string
    let nominal = parseInt(saleTableData[rowIndex].product_subtotal.replace("Rp ", "").replaceAll(",", ""));
    if (isNaN(nominal) !== true) { 
      if (saleTotalValue === undefined) saleTotalValue = 0;
      saleTotalValue += nominal; 
    }
  }

  // Format final total amount
  if (saleTotalValue === undefined) {
    // If no valid products, show dash
    saleTotal = `Rp -`;
  } else {
    // For certain sale types (2=Racikan, 4=Halodoc, 5=GoApotik, 6=Tokopedia, 7=Shopee), add special discount
    // For other types, subtract special discount
    if ([2, 4, 5, 6, 7].includes(penjualanData.sale_typ)) {
      saleTotal = `Rp ${formatCurrency(saleTotalValue + (penjualanData.special_discount ? parseInt(penjualanData.special_discount.replaceAll(',', '')) : 0))}`;
    } else {
      saleTotal = `Rp ${formatCurrency(saleTotalValue - (penjualanData.special_discount ? parseInt(penjualanData.special_discount.replaceAll(',', '')) : 0))}`;
    }
  }

  // Submit Penjualan Functionality
  const [addPenjualanMessage, setAddPenjualanMessage] = useState([0, "", null]);
  const [newPenjualanSubmitData, setNewPenjualanSubmitData] = useState(null);
  useFunctionsFetch(
    undefined,
    "/sales/create",
    newPenjualanSubmitData,
    optionsBinding["kasir"],
    newPenjualanSubmitData,
    (res) => {
      setNewPenjualanSubmitData(null)
      if (res === 'OK') {
        sendPrint();
        setCurrentPopup('add-sale-success');
      }
    }
  )

  function submitPenjualanHandler() {
    if (addPenjualanMessage[2]) clearTimeout(addPenjualanMessage[2]);
    setAddPenjualanMessage([0, "", null])

    try {
      if (!penjualanData.sale_id) throw new Error(`Penjualan tidak mempunyai ID yang valid!`);
      if (!penjualanData.sale_date) throw new Error(`Penjualan tidak mempunyai tanggal yang valid!`);
      if (!penjualanData.sale_payment) throw new Error(`Penjualan tidak mempunyai metode pembayaran yang valid!`);
      if (!(penjualanData.sale_cust_verify === true)) throw new Error(`Penjualan tidak mempunyai customer yang terverifikasi!`);
      if (saleTableData.length === 0) throw new Error(`Penjualan tidak mempunyai produk!`);
      if ((penjualanData.sale_typ === 3) && (penjualanData.sale_payment !== "Cash")) throw new Error(`Retur hanya bisa dilakukan dengan pembayaran Cash!`);

      if (penjualanData.sale_typ !== 3) {
        saleTableData.forEach((indivProduct, idx) => {
          if ((!indivProduct.product_data))
            throw new Error(`Produk nomor ${idx + 1} bukan produk yang terdaftar pada sistem!`);
          if ((!indivProduct.sale_quantity) || (parseFloat(indivProduct.sale_quantity) <= 0))
            throw new Error(`Produk nomor ${idx + 1} tidak mempunyai jumlah yang valid!`);
          if (!indivProduct.sale_format)
            throw new Error(`Produk nomor ${idx + 1} tidak mempunyai unit / satuan yang valid!`);
        })
      } else {
        saleTableData.forEach((indivProduct, idx) => {
          if ((!indivProduct.product_data))
            throw new Error(`Produk nomor ${idx + 1} bukan produk yang terdaftar pada sistem!`);
          if ((!indivProduct.sale_quantity) || (parseFloat(indivProduct.sale_quantity) <= 0))
            throw new Error(`Produk nomor ${idx + 1} tidak mempunyai jumlah yang valid!`);
          if (!indivProduct.sale_format)
            throw new Error(`Produk nomor ${idx + 1} tidak mempunyai unit / satuan yang valid!`);
          if (!indivProduct.product_ed)
            throw new Error(`Produk nomor ${idx + 1} tidak mempunyai expiry date yang valid!`);
        })
      }

      let processedTableData = saleTableData.map((prod) => {
        let newProd = { 
          ...prod,
          std_sale_quantity: parseInt((prod.sale_format === prod.product_data.packaging) ? (prod.sale_quantity * prod.product_data.qty_pack) : (prod.sale_quantity)),
          product_subtotal: prod.product_subtotal.replace("Rp ", ""),
        }
        newProd.product_formatsaleprice = (
          parseFloat(newProd.product_subtotal.replaceAll(",", "") / newProd.std_sale_quantity) 
        )
        
        delete newProd.sale_quantity
        return newProd;
      })

      setAllowTimeChange(false);
      setNewPenjualanSubmitData({ 
        ...penjualanData, 
        sale_date: new Date(penjualanData.sale_date),
        sales: processedTableData,
        tval: parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")),
        fee: paymentFee
      });

    } catch (submitSaleError) {
      setAddPenjualanMessage([
        2,
        submitSaleError.message,
        setTimeout(() => { setAddPenjualanMessage([0, "", null]); }, 5000)
      ])
    }
    
  }

  function resetPage() {
    setAllowTimeChange(true);
    [tdate, tmonth, tyear, nhours, nmins] = [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear(), new Date().getHours(), new Date().getMinutes()];

    let newPenjualanData = { 
      ...penjualanDataBlank, 
      sale_date: `${tyear}-${`${tmonth}`.length > 1 ? tmonth : `0${tmonth}`}-${`${tdate}`.length > 1 ? tdate : `0${tdate}`}T${`${nhours}`.length > 1 ? nhours : `0${nhours}`}:${`${nmins}`.length > 1 ? nmins : `0${nmins}`}`
    }
    setPenjualanData({ ...newPenjualanData });
    setSaleTableData([]);
    setMoneyProvided("");
    setPaymentFee(0);
    setCallSyncSaleCount((prev) => prev + 1);
    setCallUpdateProducts(true);
  }

  // Print Resi Functionality
  async function sendPrint() {
    let printInst = [];
    printInst.push({ type: "logo" });
    printInst.push({ 
      ...printConfig.header,
      cashier: currentUser.displayName,
      id: `${Date.parse(penjualanData.sale_date).toString(16)}I${penjualanData.sale_id.toString(16)}`.toUpperCase(),
      time: Date.parse(penjualanData.sale_date)
    });
    printInst.push({ type: "newline" });
    printInst.push({ type: "saleTableHeader" });
    printInst.push({ type: "divider" });
    let printSubtotal = saleTotalValue;

    if (penjualanData.sale_typ != 2) {
      saleTableData.forEach((saleItem) => {
        if (
          penjualanData.sale_cust_typ === 'v'
          && saleItem.product_data.s_price_b
          && (saleItem.product_data.s_price_b < saleItem.product_data.s_price)
          ) {
          printInst.push({
            type: "item",
            qty: saleItem.sale_quantity,
            prod: saleItem.product_format_name,
            price: formatCurrency((saleItem.product_data.s_price / ((saleItem.sale_format === saleItem.product_data.unit) ? saleItem.product_data.qty_pack : 1)) * parseInt(saleItem.sale_quantity)),
            discount: formatCurrency(((saleItem.product_data.s_price - saleItem.product_data.s_price_b) / ((saleItem.sale_format === saleItem.product_data.unit) ? saleItem.product_data.qty_pack : 1)) * parseInt(saleItem.sale_quantity))
          })
        } else {
          printInst.push({
            type: "item",
            qty: saleItem.sale_quantity,
            prod: saleItem.product_format_name,
            price: `${formatCurrency(Math.round(saleItem.product_formatsaleprice.replaceAll(",", "")) * parseInt(saleItem.sale_quantity))}`
          })
        }
      });
    } else {
      printInst.push({
        type: "item",
        qty: "1",
        prod: "Racikan Obat",
        price: saleTotal.replace("Rp ", "")
      })
    }

    printInst.push({ type: "newline" });
    printInst.push({ type: "divider" });
    if (penjualanData.sale_typ === 2) {
      printInst.push({ 
        type: "variable-value",
        variable: "Subtotal",
        value: (penjualanData.special_discount.length > 0) ? 
        formatCurrency(printSubtotal + parseInt(penjualanData.special_discount.replaceAll(',', ''))) 
        : formatCurrency(printSubtotal)
      });
    } else if ([1, 3].includes(penjualanData.sale_typ)) {
      if (penjualanData.special_discount.length > 0) {
        printInst.push({ 
          type: "variable-value",
          variable: "Discount",
          value: `-${formatCurrency(parseInt(penjualanData.special_discount.replaceAll(',', '')))}` 
        });
      }
      printInst.push({ 
        type: "variable-value",
        variable: "Subtotal",
        // value: formatCurrency(printSubtotal) 
        value: saleTotal.replace("Rp ", "")
      });
    } else if ([4, 5, 6, 7].includes(penjualanData.sale_typ)) {
      if (penjualanData.special_discount.length > 0) {
        printInst.push({ 
          type: "variable-value",
          variable: "Adjustments",
          value: `${formatCurrency(parseInt(penjualanData.special_discount.replaceAll(',', '')))}` 
        });
      }
      printInst.push({ 
        type: "variable-value",
        variable: "Subtotal",
        value: (penjualanData.special_discount.length > 0) ? 
        formatCurrency(printSubtotal + parseInt(penjualanData.special_discount.replaceAll(',', ''))) 
        : formatCurrency(printSubtotal)
      });
    }
    if (paymentFee > 0) {
      printInst.push({ 
        type: "variable-value",
        variable: `Admin Fee (${paymentFee}%)`,
        value: formatCurrency(parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) * paymentFee / 100) 
      });
    }
    printInst.push({ type: "divider" });
    printInst.push({ 
      type: "variable-value",
      variable: "Total",
      // value: saleTotal.replace("Rp ", "")
      value: formatCurrency(Math.round(parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) + (parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) * paymentFee / 100)))
    });
    
    printInst.push({ 
      type: "variable-value",
      variable: (penjualanData.sale_typ === 3) ? "Retur" : penjualanData.sale_payment,
      value: moneyProvided
    });
    printInst.push({ type: "divider" });
    printInst.push({ 
      type: "variable-value",
      variable: "Change",
      value: `Rp ${formatCurrency(parseInt(moneyProvided.replaceAll(",", "")) 
        - (Math.round(parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) + (parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) * paymentFee / 100)) ))}` != "Rp NaN" ?
        formatCurrency(Math.max(parseInt(moneyProvided.replaceAll(",", "")) 
        - (Math.round(parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) + (parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) * paymentFee / 100))), 0)) : "Rp -"
    });

    printInst.push({ type: "end" });

    await fetch(`http://localhost:23020/print-resi`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        logoPath: printConfig.logoPath,
        instructions: printInst
      })
    })

  }

  // Pembayaran and Pembayaran Search Functionality
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [paymentFee, setPaymentFee] = useState(0);
  useFunctionsFetch(
    undefined, 
    "/sales/payment-options", 
    {}, 
    optionsBinding["kasir"], 
    true, 
    (res) => {
      setPaymentOptions(res)
      setLoadProgress((prev) => prev + 1)
    }
  )
  const [pembayaranSearch, clearPembayaranSearch, queryPembayaranSearch, pembayaranKeyDownHandler] = useSearchProvider(
    (penjualanData.sale_typ === 4) 
      ? [{"pembayaran": "Halodoc"}] 
      : (penjualanData.sale_typ === 5) 
      ? [{"pembayaran": "GoApotik"}]
      : (penjualanData.sale_typ === 6)
      ? [{"pembayaran": "Tokopedia"}]
      : (penjualanData.sale_typ === 7)
      ? [{"pembayaran": "Shopee"}]
      : paymentOptions, 
    "pembayaran",
    setPenjualanData, 
    "sale_payment"
  );
  useEffect(() => {
    let foundPayment = paymentOptions.find((item) => item.pembayaran === penjualanData.sale_payment)
    if (foundPayment) {
      setPaymentFee(foundPayment.fee)
    }
  }, [ penjualanData.sale_payment ])

  // Verify Customer Functionality
  const [callVerifyCustomer, setCallVerifyCustomer] = useState(false);
  useFunctionsFetch(
    undefined,
    "/customers/find",
    { tel: penjualanData.sale_customer },
    optionsBinding['kasir'],
    callVerifyCustomer,
    (res) => {
      setCallVerifyCustomer(false);
      if (res !== null) {
        setPenjualanData((prev) => ({
          ...prev,
          sale_cust_verify: true,
          sale_cust_typ: res.typ
        }))
        setSaleTableData((prevTableData) => {
          return prevTableData.map((prod) => ({
            ...prod,
            product_formatsaleprice: 
              (prod.product_data) ? 
              (res.typ === "v") ? 
              (prod.product_data.s_price_b) ? 
              formatCurrency(prod.product_data.s_price_b) : 
              formatCurrency(prod.product_data.s_price) : 
              formatCurrency(prod.product_data.s_price) : ""
          }))
        })
      } else {
        setPenjualanData((prev) => ({
          ...prev,
          sale_cust_type: undefined,
          sale_cust_verify: false
        }))
      }
    }
  )
  function verifyCustomerHandler(clickEvt) {
    if (clickEvt) clickEvt.preventDefault();
    setCallVerifyCustomer(true);
  }

  // Continue from Save
  useEffect(() => {
    setCallUpdateProducts(true);

    if (props.saveData.kasir) {
      setPenjualanData(() => {
        if (
          props.saveData.kasir.penjualanData 
          && props.saveData.kasir.penjualanData.sale_date
          && (props.saveData.kasir.allowTimeChange)
        ) {
          [tdate, tmonth, tyear, nhours, nmins] = [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear(), new Date().getHours(), new Date().getMinutes()];

          return {
            ...props.saveData.kasir.penjualanData,
            sale_date: `${tyear}-${`${tmonth}`.length > 1 ? tmonth : `0${tmonth}`}-${`${tdate}`.length > 1 ? tdate : `0${tdate}`}T${`${nhours}`.length > 1 ? nhours : `0${nhours}`}:${`${nmins}`.length > 1 ? nmins : `0${nmins}`}`
          }
        } else return { ...props.saveData.kasir.penjualanData };
      });
      setSaleTableData(props.saveData.kasir.saleTableData);
    } else {
      props.setSaveData((prev) => ({
        ...prev,
        kasir: {
          saleTableData: [],
          penjualanData: penjualanDataBlank,
        }
      }))
    }

  }, [])

  // Update Save
  useEffect(() => {
    props.setSaveData((prev) => ({
      ...prev,
      kasir: {
        penjualanData: penjualanData,
        saleTableData: saleTableData,
        allowTimeChange: allowTimeChange
      }
    }));
  }, [ penjualanData, saleTableData ])
  
  // Change Calculator
  const [moneyProvided, setMoneyProvided] = useState("");
  function handleMoneyProvidedChange(changeEvt) {
    let cleanValue = changeEvt.target.value.replaceAll(',', '');
    if (!/^(([1-9][\d]*)|(0?))?$/.test(cleanValue)) return;
    else setMoneyProvided(formatCurrency(cleanValue));
  }

  // Sale Type Change Handler
  useEffect(() => {
    // If sale type is not return (3), filter products with remaining stock and set expiry date
    if (penjualanData.sale_typ !== 3) {
      setSaleTableData((prev) => {
        let newData = [ ...prev ];
        // Filter out products with no remaining stock
        newData = newData.filter((product) => {
          return (product.product_data ? product.product_data.rem_stocks.length > 0 : true);
        }).map((product) => ({
          // Set expiry date to first remaining stock's expiry date
          ...product,
          product_ed: product.product_data ? product.product_data.rem_stocks[0].expirydate : ""
        }))
        return newData;
      });
    } else {
      // For return sales (type 3), clear all expiry dates
      setSaleTableData((prev) => {
        let newData = [ ...prev ];
        newData = newData.map((product) => ({
          ...product,
          product_ed: ""
        }))
        return newData;
      });
    }
    setMoneyProvided("")
    if ([1, 2, 3].includes(penjualanData.sale_typ)) {
      if ([4, 5, 6, 7].includes(prevTyp)) {
        setPenjualanData((prev) => ({ 
          ...prev,
          "sale_payment": ""
        }))
      }
      handlePenjualanFormChange({ "target": { "name": "special_discount", "value": penjualanData.special_discount }, "type": "check" })
    }
  }, [ penjualanData.sale_typ ]);

  // Change Halodoc/GoApotik Functionality
  const [prevTyp, setPrevTyp] = useState(penjualanData.sale_typ);
  const [onlineOrderMsg, setOnlineOrderMsg] = useState([0, null]);

  // Handler for confirming online order number (Halodoc/GoApotik)
  function handleConfirmOrderNo(evt) {
    evt.preventDefault();

    // Clear any existing timeout for online order messages
    if (onlineOrderMsg[1]) {
      clearTimeout(onlineOrderMsg[1])
      setOnlineOrderMsg([0, null]);
    }

    // Get order number from input field
    let inputNo = document.querySelector("#online-orderNo").value

    // If order number is empty, show error message for 5 seconds
    if (inputNo.length <= 0) {
      setOnlineOrderMsg([2, setTimeout(() => { setOnlineOrderMsg([0, null]); }, 5000)])
    } else {
      // Update sale data with order number and payment type
      setPenjualanData((prev) => {
        let newData = { 
          ...prev,
          // Set payment type based on sale type (4=Halodoc, 5=GoApotik, 6=Tokopedia, 7=Shopee)
          "sale_payment": (penjualanData.sale_typ === 4) ? "Halodoc" : 
          (penjualanData.sale_typ === 5) ? "GoApotik" :
          (penjualanData.sale_typ === 6) ? "Tokopedia" :
          "Shopee"
        }
        newData.order_no = inputNo;
        return newData
      })
      // Close popup after successful confirmation
      setCurrentPopup(null)
    }
  }

  // Rendered Component
  return (
    <div className="tambahPenjualan">
      {blockDisplay && 
        <div className='loadingBlock'>
          <div className='lds-dual-ring'></div>
        </div>
      }

      {(currentPopup && (currentPopup === 'add-sale-success')) &&
        <PopupWindow innerCard={
          <div>
            <div className="messagePopup">
              <h4>Berhasil</h4>
              <p className="mb-5">
                Penjualan baru telah berhasil terdaftar kepada sistem! 
              </p>
              <div style={{ display: "flex" }}>
                <Button 
                  className="printResiBtn mb-0 ms-auto"
                  iconClasses="bi bi-printer" 
                  btnText="Cetak Ulang" 
                  clickHandler={() => { 
                    sendPrint(); 
                  }} 
                />
                <Button 
                  className="closeSaleSuccessBtn mb-0 ms-3"
                  iconClasses="bi bi-check-circle" 
                  btnText="OK" 
                  clickHandler={() => { 
                    resetPage();
                    setCurrentPopup(null); 
                  }} 
                />
              </div>
            </div> 
          </div>
        } />
      }
      {(currentPopup && currentPopup === 'customers/create') &&
        <PopupWindow innerCard={
          <TambahCustomer 
            callerTab={optionsBinding["kasir"]}
            closePopup={() => { setCurrentPopup(null) }}
            successPopup={() => { setCurrentPopup('customers/create-success') }}
          />
        } />
      }
      {(currentPopup && currentPopup === 'customers/create-success') &&
        <PopupWindow message={[
          "Berhasil", 
          "Customer baru telah berhasil terdaftar kepada sistem!"
        ]} closePopup={() => { setCurrentPopup(null) }} />
      }
      {(currentPopup && currentPopup === 'input-online') &&
        <PopupWindow innerCard={
          <div className="online-order">
            <form onSubmit={handleConfirmOrderNo}>
              <h5 className="form-title">Transaksi {
                penjualanData.sale_typ === 4 ? "Halodoc" : 
                penjualanData.sale_typ === 5 ? "GoApotik" :
                penjualanData.sale_typ === 6 ? "Tokopedia" :
                "Shopee"
              }</h5>
              <div style={{ position: "relative", marginTop: "-1em", marginBottom: "3em" }}>
                <label htmlFor="online-orderNo">Nomor Order <span className="reqStar">*</span></label><br />
                <input id="online-orderNo" name="online-orderNo" type="text" onInput={(ev) => {ev.target.value = ev.target.value.toUpperCase()}}/> 
              </div>
              <div className="d-flex justify-content-end mt-3 mb-4">
                <Button 
                  className="cancel-onlineNo-btn me-3"
                  iconClasses="bi bi-x-lg"
                  btnText="Batal" 
                  clickHandler={() => {
                    if (onlineOrderMsg[1]) {
                      clearTimeout(onlineOrderMsg[1])
                      setOnlineOrderMsg([0, null]);
                    }
                    if (penjualanData.order_no && (prevTyp != 4) && (prevTyp != 5) && (prevTyp != 6) && (prevTyp != 7)) {
                      setPenjualanData((prev) => {
                        let newData = { ...prev }
                        delete newData.order_no;
                        newData.sale_typ = prevTyp;
                        return newData
                      })
                    } else {
                      setPenjualanData((prev) => {
                        let newData = { ...prev }
                        newData.sale_typ = prevTyp;
                        return newData
                      })
                    }
                    setCurrentPopup(null);
                  }} 
                />
                <Button 
                  className="confirm-onlineNo-btn"
                  iconClasses="bi bi-check-lg"
                  btnText="Konfirmasi" 
                  clickHandler={handleConfirmOrderNo} 
                />
              </div>
              <OperationMessage status={onlineOrderMsg[0]} msg={`Nomor order tidak boleh kosong untuk transaksi ${
                penjualanData.sale_typ === 4 ? "Halodoc" : 
                penjualanData.sale_typ === 5 ? "GoApotik" :
                penjualanData.sale_typ === 6 ? "Tokopedia" :
                "Shopee"
              }!`} />
            </form>
          </div>
        } />
      }
      
      {warnQtyMessage &&
        <PopupWindow message={[
          "Stok produk sangat terbatas!", 
          warnQtyMessage
        ]} closePopup={() => { setWarnQtyMessage(null) }} />
      }
      {maxQtyMessage &&
        <PopupWindow message={[
          "Jumlah melebihi sisa stok barang!", 
          maxQtyMessage
        ]} closePopup={() => { setMaxQtyMessage(null) }} />
      }

      <h3 className="tabHeading">Kasir</h3>
      
      <div className="d-flex align-items-center" style={{ marginBottom: "1em" }}>
        <h5 className="sectionHeading mb-0 me-auto">Tipe Transaksi</h5>
        <Toggler options={[
            ["Standar", penjualanData.sale_typ === 1, () => { 
              setPrevTyp(penjualanData.sale_typ);
              setPenjualanData((prev) => {
                let newData = { ...prev, sale_typ: 1 };
                if (newData.order_no) delete newData.order_no;
                return newData
              }); 
            }],
            ["Halodoc", penjualanData.sale_typ === 4, () => { 
              setPrevTyp(penjualanData.sale_typ);
              setPenjualanData((prev) => ({ ...prev, sale_typ: 4 })); 
              setCurrentPopup('input-online');
            }],
            ["GoApotik", penjualanData.sale_typ === 5, () => { 
              setPrevTyp(penjualanData.sale_typ);
              setPenjualanData((prev) => ({ ...prev, sale_typ: 5 })); 
              setCurrentPopup('input-online');
            }],
            ["Tokopedia", penjualanData.sale_typ === 6, () => { 
              setPrevTyp(penjualanData.sale_typ);
              setPenjualanData((prev) => ({ ...prev, sale_typ: 6 })); 
              setCurrentPopup('input-online');
            }],
            ["Shopee", penjualanData.sale_typ === 7, () => { 
              setPrevTyp(penjualanData.sale_typ);
              setPenjualanData((prev) => ({ ...prev, sale_typ: 7 })); 
              setCurrentPopup('input-online');
            }],
            ["Racikan", penjualanData.sale_typ === 2, () => { 
              setPrevTyp(penjualanData.sale_typ);
              setPenjualanData((prev) => {
                let newData = { ...prev, sale_typ: 2 };
                if (newData.order_no) delete newData.order_no;
                return newData
              }); 
            }],
            ["Retur", penjualanData.sale_typ === 3, () => { 
              setPrevTyp(penjualanData.sale_typ);
              setPenjualanData((prev) => {
                let newData = { ...prev, sale_typ: 3 };
                if (newData.order_no) delete newData.order_no;
                return newData
              }); 
            }],
          ]} style={{ fontSize:"18px", padding: "0.7em 1.2em" }} />
      </div>

      <div className="d-flex mt-4">
        <div>
          <h5 className="sectionHeading mb-4">Informasi {(penjualanData.sale_typ === 3) ? "Retur": "Penjualan"}</h5>
        </div>
        {penjualanData.order_no && currentPopup !== 'input-online' &&         
          <div className="ms-auto">
            <h5 className="sectionHeading mb-2">
              <span className="online-order-label">ORDER {
                (penjualanData.sale_typ === 4) ? "HALODOC" : 
                (penjualanData.sale_typ === 5) ? "GOAPOTIK" :
                (penjualanData.sale_typ === 6) ? "TOKOPEDIA" :
                "SHOPEE"
              }</span>
              {penjualanData.order_no}
            </h5>
          </div>
        }
      </div>

      <form autoComplete="off" style={{ marginBottom: "2em" }}>
        <div>
          <div style={{ width: "180px" }}>
            <label htmlFor="sale_id">ID <span className="reqStar">*</span></label><br />
            <input id="sale_id" name="sale_id" type="text" onChange={handlePenjualanFormChange} value={penjualanData.sale_id} disabled /> 
          </div>
          <div style={{ width: "350px" }}>
            <label htmlFor="sale_date">Tanggal dan Waktu <span className="reqStar">*</span></label><br />
            <input id="sale_date" name="sale_date" type="datetime-local" onChange={handlePenjualanFormChange} value={penjualanData.sale_date} /> 
          </div>
          <div style={{ flexGrow: "1" }} className="selectable">
            <label htmlFor="sale_payment">Metode Pembayaran <span className="reqStar">*</span></label><br />
            <input 
              id="sale_payment"
              name="sale_payment" 
              type="text" 
              onChange={() => { queryPembayaranSearch(""); }} 
              value={penjualanData.sale_payment} 
              readOnly={true}
              style={{ cursor: "pointer" }}
              onFocus={() => { queryPembayaranSearch(""); }}
              onBlur={() => { clearPembayaranSearch(); }}
              onKeyDown={pembayaranKeyDownHandler}
            /> 
            {pembayaranSearch.length > 0 ? 
              <div className={`pembayaran searchContainer`}>
                {pembayaranSearch}
              </div> 
            : ""}
          </div>
        </div>
        <div>
          <div style={{ flexGrow: "1" }} className="verifyCustomerDiv">
            <label htmlFor="sale_customer">Nomor Telepon Customer <span className="reqStar">*</span></label><br />
            <input 
              id="sale_customer"
              name="sale_customer" 
              type="text" 
              onChange={handlePenjualanFormChange} 
              onKeyDown={(keyEvt) => {
                if (keyEvt.key === "Enter") {
                  verifyCustomerHandler();
                }
              }}
              value={penjualanData.sale_customer} 
              placeholder="0XXXXXXXXXXX"
            /> 
            {penjualanData.sale_cust_verify === true
            ? <i className="bi bi-check-circle"></i> 
            : penjualanData.sale_cust_verify === false 
            ? <i className="bi bi-x-circle"></i> 
            : undefined}
          </div>
          <div>
            <Button 
              className="verifyCustomerBtn me-3"
              iconClasses="bi bi-search"
              btnText="Verifikasi Nomor Telepon" 
              clickHandler={verifyCustomerHandler} 
            />
          </div>
          <div>
            <Button 
              className="addCustomerBtn"
              iconClasses="bi bi-bag-plus" 
              btnText="Customer Baru" 
              clickHandler={() => { setCurrentPopup('customers/create') }} 
            />
          </div>
        </div>

      </form>

      <h5 className="sectionHeading">Daftar Produk</h5>

      <div className="customTable" style={{ marginBottom: "2em" }}>
        <TableRow columns={[
          ["3.5%", "No"],
          ["34.5%", "Produk"],
          ["13.5%", "Expired Date"],
          ["12%", "Harga Jual"],
          ["2%", ""],
          ["5.5%", "Qty"],
          ["10%", "Unit"],
          ["2%", ""],
          ["13%", "Subtotal"],
          ["4%", ""]
          ]} />
        {shownRows} 
        <hr style={{ marginTop: "-1px", marginBottom: "0.5em" }} />
        <TableRow columns={[
          ["3.5%", ""],
          ["34.5%", ""],
          ["13.5%", ""],
          ["12%", ""],
          ["2%", ""],
          ["5.5%", ""],
          ["10%", (penjualanData.sale_typ === 2) ? "Margin" : ([4, 5, 6, 7].includes(penjualanData.sale_typ)) ? "Adjustment" : "Discount"],
          ["2%", ""],
          ["13%", 
            <div className="editable" style={{ transform: "translateX(-12px)" }}>
              <span style={{ position: "absolute", zIndex: "-1", transform: "translate(0.75em, 9px)" }}>Rp</span>
              <input
                autoComplete="off"
                type="text"
                placeholder="-"
                onChange={handlePenjualanFormChange}
                style={{ padding: "0.5em 0.75em 0.5em 2.20em", backgroundColor: "transparent" }}
                name="special_discount"
                id="special_discount"
                value={penjualanData.special_discount}
              />
            </div>
          ],
          ["4%", ""]
        ]} additionalClass="brd-0 discountRow" />
        <TableRow columns={[
          ["3.5%", ""],
          ["34.5%", ""],
          ["13.5%", ""],
          ["12%", ""],
          ["2%", ""],
          ["5.5%", ""],
          ["10%", `Subtotal`],
          ["2%", ""],
          ["13%", saleTotal != "Rp -" ? saleTotal : "Rp -"],
          ["4%", ""]
        ]} additionalClass="brd-0" />
        {paymentFee != 0 && 
          <TableRow columns={[
            ["3.5%", ""],
            ["34.5%", ""],
            ["13.5%", ""],
            ["12%", ""],
            ["2%", ""],
            ["5.5%", ""],
            ["10%", `Fee (${paymentFee}%)`],
            ["2%", ""],
            ["13%", saleTotal != "Rp -" ? `Rp ${formatCurrency(Math.round(parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) * (paymentFee / 100)))}` : "Rp -"],
            ["4%", ""]
          ]} additionalClass="brd-0 mt-2" />
        }
        <hr style={{ marginTop: "1em", marginBottom: "0.5em" }} />
        <TableRow columns={[
          ["3.5%", ""],
          ["34.5%", ""],
          ["13.5%", ""],
          ["12%", ""],
          ["2%", ""],
          ["5.5%", ""],
          ["10%", "Total"],
          ["2%", ""],
          ["13%", saleTotal != "Rp -" ? `Rp ${formatCurrency(Math.round(parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) + (parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) * paymentFee / 100)))}` : "Rp -"],
          ["4%", ""]
        ]} customStyle={{ borderBottom: "0px", marginTop: "10px" }} />
        {penjualanData.sale_payment &&
          <>
            <TableRow columns={[
              ["3.5%", ""],
              ["34.5%", ""],
              ["13.5%", ""],
              ["12%", ""],
              ["2%", ""],
              ["5.5%", ""],
              ["10%", penjualanData.sale_payment],
              ["2%", ""],
              ["13%", 
                <div className="editable" style={{ transform: "translateX(-12px)" }}>
                  <span style={{ position: "absolute", zIndex: "-1", transform: "translate(0.75em, 9px)" }}>Rp</span>
                  <input
                    autoComplete="off"
                    type="text"
                    placeholder="-"
                    onChange={handleMoneyProvidedChange}
                    style={{ padding: "0.5em 0.75em 0.5em 2.20em", backgroundColor: "transparent" }}
                    name="moneyProvided"
                    id="moneyProvided"
                    value={moneyProvided}
                  />
                </div>
              ],
              ["4%", ""]
            ]} additionalClass="brd-0 discountRow" />
            <hr style={{ marginTop: "0.5em", marginBottom: "0.5em" }} />
            <TableRow columns={[
              ["3.5%", ""],
              ["34.5%", ""],
              ["13.5%", ""],
              ["12%", ""],
              ["2%", ""],
              ["5.5%", ""],
              ["10%", "Change"],
              ["2%", ""],
              ["13%", 
                `Rp ${formatCurrency(parseInt(moneyProvided.replaceAll(",", "")) 
                  - (Math.round(parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) + (parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) * paymentFee / 100)) ))}` != "Rp NaN" ?
                `Rp ${formatCurrency(Math.max(parseInt(moneyProvided.replaceAll(",", "")) 
                  - (Math.round(parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) + (parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) * paymentFee / 100))), 0))}` : "Rp -"
              ],
              ["4%", ""]
            ]} customStyle={{ borderBottom: "0px" }} ending={true} />
          </>
        }

      </div>

      <div style={{ display: "flex" }}>
        <Button 
          className="resetPenjualanBtn me-auto"
          iconClasses="bi bi-x-circle" 
          btnText="Reset" 
          clickHandler={() => { 
            resetPage();
          }}  
        />

        <Button 
          className="submitpenjualanBtn ms-4"
          disabled={(parseInt(moneyProvided.replaceAll(",", "")) 
          - (Math.round(parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) + (parseInt(saleTotal.replace("Rp ", "").replaceAll(",", "")) * paymentFee / 100)) ) >= 0) ? false : true}
          iconClasses="bi bi-cart" 
          btnText={`Selesaikan ${(penjualanData.sale_typ === 3) ? "Retur": "Penjualan"}`} 
          clickHandler={submitPenjualanHandler}  
        />
      </div>
      
      <OperationMessage status={addPenjualanMessage[0]} msg={addPenjualanMessage[1]} customStyle={{ margin: "1em 0" }} />

    </div>
  )
}