import { useContext, useEffect, useState } from "react"
import { OptionsContext } from "../../Contexts";
import useSearchProvider from "../../Utilities/Search/search"
import { findMatchesArrayObject, formatCurrency, useFunctionsFetch } from "../../UtilFunctions";
import Button from "../../Utilities/Button/button";
import OperationMessage from "../../Utilities/OperationMessage/operationmessage";
import "../../Utilities/Table/table.css"
import TableRow from "../../Utilities/Table/tablerow";
import "./tambahpembelian.css"

export default function TambahPembelian() {
  const optionsBinding = useContext(OptionsContext)
  const [tdate, tmonth, tyear] = [new Date().getDate(), new Date().getMonth() + 1, new Date().getFullYear()];

  // Sync Purchase ID
  const [callSyncPurchaseCount, setCallSyncPurchaseCount] = useState(1)
  useFunctionsFetch(
    undefined,
    "/system/persistent-data/accumulatedData/totalPurchasesCount",
    {},
    optionsBinding["add-purch"],
    callSyncPurchaseCount,
    (res) => {
      setPembelianData((prev) => ({
        ...prev,
        pur_id: res + 1
      }))
      setCallSyncPurchaseCount((prev) => { return prev - 1; })
    }
  )

  // Pembelian Form Functionality
  const [addPembelianMessage1, setAddPembelianMessage1] = useState([0, ""]);
  const [view, setView] = useState("getPembelianID");
  const pembelianDataBlank = {
    pur_id: 0,
    pur_supplier: "",
    pur_date:
      `${tyear}-${`${tmonth}`.length > 1 ? tmonth : `0${tmonth}`}-${`${tdate}`.length > 1 ? tdate : `0${tdate}`}`,
    pur_payment: "",
    return: {
      prev_id: null
    }
  }
  const [pembelianData, setPembelianData] = useState({ ...pembelianDataBlank })

  function handlePembelianFormChange(changeEvent) {
    setPembelianData((prevData) => ({
      ...prevData,
      [changeEvent.target.id]: changeEvent.target.value
    }))

    if (changeEvent.target.id === "pur_supplier") querySupplierSearch(changeEvent.target.value)
    if (changeEvent.target.id === "pur_payment") queryPembayaranSearch(changeEvent.target.value)
  }

  // Search Supplier Function
  const [suppliersData, setSuppliersData] = useState([])
  const [supplierSearch, clearSupplierSearch, querySupplierSearch, supplierKeyDownHandler] = useSearchProvider(
    suppliersData.map((supplier) => ({ "supplier": supplier })), "supplier", setPembelianData, "pur_supplier", 5);

  const [callGetSuppliers, setCallGetSuppliers] = useState(1);
  function refreshSuppliers() { setCallGetSuppliers((prev) => { return prev + 1; }) }

  useFunctionsFetch(
    undefined,
    "/system/persistent-data/productSuppliers/suppliers",
    {},
    optionsBinding["add-purch"],
    callGetSuppliers,
    (res) => {
      setCallGetSuppliers((prev) => { return prev - 1; })
      setSuppliersData(res)
    }
  )

  // Add Supplier Function
  const [callAddSupplier, setCallAddSupplier] = useState(0);
  const [newSupplierData, setNewSupplierData] = useState("");
  const [newSupplierBody, setNewSupplierBody] = useState({});
  useFunctionsFetch(
    undefined,
    "/suppliers/create",
    newSupplierBody,
    optionsBinding["add-purch"],
    callAddSupplier,
    (res) => {
      if (res.acknowledged === true) {
        setCallAddSupplier((prev) => { return prev - 1; });
        refreshSuppliers()
        setAddPembelianMessage1([1, "Supplier baru telah berhasil di daftarkan ke sistem!"])
        setTimeout(() => { setAddPembelianMessage1([0, ""]); }, 5000);
      } else {
        setAddPembelianMessage1([2, "Terjadi error pada fungsi penambahan supplier!"])
        setTimeout(() => { setAddPembelianMessage1([0, ""]); }, 5000);
      }
    }
  )

  // Search Product Function
  const [productsData, setProductsData] = useState([])
  let searchProductsArray = productsData.map((info) => ({
    pid: info.pid,
    packaging: info.packaging,
    qty_pack: info.qty_pack,
    product_format_name: `${info.p_name} (${info.qty_pack} ${info.unit} / ${info.packaging})`
  }));
  const [indivProductIndex, setIndivProductIndex] = useState(0)
  const [productsSearch, clearProductsSearch, queryProductsSearch, productsKeyDownHandler] = useSearchProvider(
    searchProductsArray,
    "product_format_name",
    (setterF) => {
      let newProductData = setterF(purchaseTableData[indivProductIndex]);
      newProductData.product_format_name = newProductData.chosen_product.product_format_name;
      newProductData.product_normal_packaging = newProductData.chosen_product.packaging;
      newProductData.product_normal_qty_pack = newProductData.chosen_product.qty_pack;
      newProductData.purchase_format = newProductData.chosen_product.packaging;
      newProductData.pid = newProductData.chosen_product.pid;
      delete newProductData.chosen_product;

      setPurchaseTableData((prev) => {
        let newArray = [...prev];
        newArray[indivProductIndex] = newProductData;
        return newArray;
      })
    },
    "chosen_product",
    5,
    true
  );

  useFunctionsFetch(
    undefined,
    "/products/list",
    { query: { enabled: true } },
    optionsBinding["add-purch"],
    1,
    (res) => {
      setProductsData(res)
    }
  )

  // Search Purchase Unit
  const [indivProductFormats, setIndivProductFormats] = useState([])
  const [callShowProductFormats, setCallShowProductFormats] = useState(false)
  const [formatsSearch, clearFormatsSearch, queryFormatsSearch, formatsKeyDownHandler] = useSearchProvider(
    indivProductFormats.map((format) => ({ format: format })),
    "format",
    (setterF) => {
      let newProductData = setterF(purchaseTableData[indivProductIndex])
      setPurchaseTableData((prev) => {
        let newArray = [...prev];
        newArray[indivProductIndex] = newProductData;
        return newArray
      })
    },
    "purchase_format"
  )

  useEffect(() => {
    if (callShowProductFormats === true) {
      queryFormatsSearch("")
      setCallShowProductFormats(false)
    }
  }, [indivProductFormats])

  // Pembelian Array Functionality
  const [purchaseTableData, setPurchaseTableData] = useState([])      // Container for Table Product Info
  const [getLastPurchIdx, setGetLastPurchIdx] = useState([false, undefined]);
  const [lastPurchInfo, setLastPurchInfo] = useState(undefined);
  useFunctionsFetch(
    undefined,
    "/purchases/by-product",
    {
      pid: purchaseTableData[getLastPurchIdx[1]] ? purchaseTableData[getLastPurchIdx[1]].pid : undefined,
      limit: 1
    },
    optionsBinding["add-purch"],
    getLastPurchIdx[0],
    (res) => {
      setGetLastPurchIdx((prev) => ([false, prev[1]]));
      setLastPurchInfo(res);
    }
  )

  function handleTableDataChange(changeEvt, productIndex) {
    if ((changeEvt.target.name === "p_price")
      || (changeEvt.target.name === "purchasequantity")) {
      if (!(/^([1-9][\d]*)?$/.test(changeEvt.target.value.replaceAll(",", "")))) {
        return
      }
    }

    setPurchaseTableData((prevTableData) => {
      let newTableData = [...prevTableData];
      newTableData[productIndex][changeEvt.target.name] = changeEvt.target.value;

      if (changeEvt.target.name === "p_price") {
        newTableData[productIndex].p_price = changeEvt.target.value.replaceAll(",", "");
        newTableData[productIndex].product_formatpurchaseprice = formatCurrency(changeEvt.target.value.replaceAll(",", ""));
        newTableData[productIndex].product_subtotal =
          `Rp ${formatCurrency(parseInt(newTableData[productIndex].purchasequantity) * parseInt(changeEvt.target.value.replaceAll(",", "")))}`;

      } else if (changeEvt.target.name === "purchasequantity") {
        newTableData[productIndex].product_subtotal =
          `Rp ${formatCurrency(parseInt(newTableData[productIndex].p_price) * parseInt(changeEvt.target.value))}`
      }

      if (newTableData[productIndex].product_subtotal === "Rp NaN") {
        newTableData[productIndex].product_subtotal = "Rp -"
      }

      if (changeEvt.target.name === "product_format_name") {
        newTableData[productIndex].purchase_format = "";
        newTableData[productIndex].pid = undefined;
        newTableData[productIndex].product_normal_packaging = "";
        newTableData[productIndex].product_normal_qty_pack = 0;

        let matchObj = findMatchesArrayObject([changeEvt.target.value], searchProductsArray, "product_format_name")

        if ((matchObj.length === 1) && (matchObj[0].product_format_name === changeEvt.target.value)) {
          newTableData[productIndex].purchase_format = matchObj[0].packaging;
          newTableData[productIndex].pid = matchObj[0].pid;
          newTableData[productIndex].product_normal_packaging = matchObj[0].packaging;
          newTableData[productIndex].product_normal_qty_pack = matchObj[0].qty_pack;
        } else {
          queryProductsSearch(changeEvt.target.value);
        }
      }

      return newTableData;
    });

  }

  function addNewProductToList() {
    setPurchaseTableData((prevData) => {
      let newArray = [...prevData];
      newArray.push({
        pid: undefined,
        product_format_name: "",
        product_ed: "",
        p_price: "",
        product_formatpurchaseprice: "",
        product_normal_packaging: "",
        product_normal_qty_pack: 0,
        purchasequantity: "",
        purchase_format: "",
        product_subtotal: "Rp -"
      })
      return newArray
    })
  }

  function removeTableProduct(index) {
    setPurchaseTableData((prev) => { return prev.filter((row, idx) => { return idx !== index }) })
  }

  let shownRows = purchaseTableData.map((data, idx) =>
    <TableRow key={idx} columns={[
      ["3.5%", idx + 1
      ], ["33%",
        <div style={{ position: "relative" }} className="editable">
          <input
            autoComplete="off"
            type="text"
            className="tableProductInput"
            placeholder="Produk"
            onChange={(changeEvt) => { handleTableDataChange(changeEvt, idx); }}
            name="product_format_name"
            value={data.product_format_name}
            onFocus={() => {
              setIndivProductIndex(idx);
              queryProductsSearch(purchaseTableData[idx]["product_format_name"]);
            }}
            onBlur={() => { clearProductsSearch() }}
            onKeyDown={productsKeyDownHandler}
          />
          {((productsSearch.length > 0) && (indivProductIndex === idx)) ?
            <div className={`products searchContainer`}>
              {productsSearch}
            </div>
            : ""}
        </div>
      ], ["13.5%",
        <div className="editable" style={{ maxWidth: "100%" }}>
          <input
            autoComplete="off"
            type="date"
            className="tableProductInput"
            onChange={(changeEvt) => { handleTableDataChange(changeEvt, idx); }}
            style={{ width: "100%" }}
            name="product_ed"
            value={data.product_ed}
          />
        </div>
      ], ["12%",
        <div className="editable">
          <span style={{ position: "absolute", zIndex: "-1", transform: "translate(0.75em, 9px)" }} key={`${Math.random()}`.slice(2, -1)}>Rp</span>
          <input
            autoComplete="off"
            type="text"
            className="tableProductInput"
            placeholder="Price"
            onChange={(changeEvt) => { handleTableDataChange(changeEvt, idx); }}
            style={{ padding: "0.5em 0.75em 0.5em 2.5em", backgroundColor: "transparent" }}
            name="p_price"
            value={data.product_formatpurchaseprice}
          />
        </div>
      ], ["1.5%", <div className="text-center">x</div>
      ], ["5.5%",
        <div className="editable">
          <input
            autoComplete="off"
            type="text"
            className="tableProductInput"
            placeholder="Qty"
            onChange={(changeEvt) => { handleTableDataChange(changeEvt, idx); }}
            name="purchasequantity"
            value={data.purchasequantity}
          />
        </div>
      ], ["10%",
        <div style={{ position: "relative" }} className="selectable" >
          <input
            autoComplete="off"
            type="text"
            className="tableProductInput"
            placeholder="Unit"
            onChange={(changeEvt) => { queryFormatsSearch("") }}
            name="purchase_format"
            value={data.purchase_format}
            readOnly={true}
            style={{ cursor: "pointer" }}
            onFocus={() => {
              setIndivProductIndex(idx);
              let splitBracket1 = purchaseTableData[idx].product_format_name.split("(")
              if (splitBracket1.length < 2) return;
              let splitBracket2 = splitBracket1.slice(-1)[0].split(")")
              if (splitBracket2.length < 2) return;
              let formatInfo = splitBracket2.slice(0, 1)[0].split(" ")
              if (formatInfo.length !== 4) return;

              setCallShowProductFormats(true);
              setIndivProductFormats([formatInfo[1], formatInfo[3]])
            }}
            onBlur={() => { clearFormatsSearch() }}
            onKeyDown={formatsKeyDownHandler}
          />
          {((formatsSearch.length > 0) && (indivProductIndex === idx)) ?
            <div className={`formats searchContainer`}>
              {formatsSearch}
            </div>
            : ""}
        </div>
      ], ["2%", <div className="text-center">=</div>
      ], ["13%", data.product_subtotal
      ], ["6%",
        <div className="d-flex justify-content-between position-relative">
          {(
            purchaseTableData[idx].pid
            && lastPurchInfo
            && (lastPurchInfo.purchasedProducts.filter((item) => item.pid === purchaseTableData[idx].pid).length != 0)
            && (getLastPurchIdx[1] === idx)
          ) ?
            <div className="purchase-tooltip" style={{
              opacity: (lastPurchInfo ? '1' : '0'),
              visibility: (lastPurchInfo ? 'visible' : 'hidden')
            }}>
              <table>
                <tbody>
                  <tr>
                    <td>Purchase ID</td>
                    <td style={{ width: "20px" }}></td>
                    <td>{lastPurchInfo ? lastPurchInfo.pur_id : ""}</td>
                  </tr>
                  <tr>
                    <td>Date</td>
                    <td style={{ width: "20px" }}></td>
                    <td>{lastPurchInfo ? new Date(lastPurchInfo.pur_date).toLocaleDateString() : ""}</td>
                  </tr>
                  <tr>
                    <td>Quantity</td>
                    <td style={{ width: "20px" }}></td>
                    <td>
                      {
                        lastPurchInfo ?
                          ((lastPurchInfo.purchasedProducts.find((item) => item.pid === purchaseTableData[idx].pid).std_pur_qty % purchaseTableData[idx].product_normal_qty_pack) === 0) ?
                            `${lastPurchInfo.purchasedProducts.find((item) => item.pid === purchaseTableData[idx].pid).std_pur_qty / purchaseTableData[idx].product_normal_qty_pack} ${purchaseTableData[idx].product_format_name.split("(").slice(-1)[0].split(")")[0].split(" ")[3]}`
                            : (lastPurchInfo.purchasedProducts.find((item) => item.pid === purchaseTableData[idx].pid).std_pur_qty > purchaseTableData[idx].product_normal_qty_pack) ?
                              `${Math.floor(lastPurchInfo.purchasedProducts.find((item) => item.pid === purchaseTableData[idx].pid).std_pur_qty / purchaseTableData[idx].product_normal_qty_pack)} ${purchaseTableData[idx].product_format_name.split("(").slice(-1)[0].split(")")[0].split(" ")[3]} + ${lastPurchInfo.purchasedProducts.find((item) => item.pid === purchaseTableData[idx].pid).std_pur_qty % purchaseTableData[idx].product_normal_qty_pack}`
                              : `${lastPurchInfo.purchasedProducts.find((item) => item.pid === purchaseTableData[idx].pid).std_pur_qty} ${purchaseTableData[idx].product_format_name.split("(").slice(-1)[0].split(")")[0].split(" ")[2]}`
                          : ""
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>Price</td>
                    <td style={{ width: "20px" }}></td>
                    <td>
                      {lastPurchInfo ? `Rp ${formatCurrency(Math.round(lastPurchInfo.purchasedProducts.find((item) => item.pid === purchaseTableData[idx].pid).std_pur_price * purchaseTableData[idx].product_normal_qty_pack))}` : ""}
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
            : ""
          }
          <div style={{
            fontSize: "32px",
            lineHeight: "32px",
            color: ((purchaseTableData[idx].pid === undefined) ? "#888" : "var(--c-primary)")
          }}>
            <i
              className="bi bi-info-square"
              onMouseEnter={() => {
                if (purchaseTableData[idx].pid === undefined) return;
                setGetLastPurchIdx([true, idx])
              }}
              onMouseLeave={() => {
                setLastPurchInfo(undefined);
              }}
            ></i>
          </div>
          <div style={{ width: "32px", flexGrow: "0" }}>
            <button
              style={{
                backgroundColor: "#ef0000",
                color: "white",
                fontSize: "16px",
                lineHeight: "32px",
                width: "100%",
                height: "32px",
                borderRadius: "0.2em",
                maxWidth: "32px",
                marginLeft: "auto",
                marginRight: "1px",
                cursor: "pointer",
                border: "none"
              }}
              onClick={() => { removeTableProduct(idx) }}
            >
              X
            </button>
          </div>
        </div>
      ]
    ]} />
  )

  shownRows.push(
    <div key={"addProductBtn"}>
      <button
        style={{
          fontSize: "16px",
          border: "1px solid var(--c-secondary)",
          backgroundColor: "inherit",
          cursor: "pointer",
          padding: "0.8em 2em",
          width: "100%",
          borderRadius: "0.4em",
          margin: "2.5em 0",
          backgroundColor: "#eee",
          color: "black"
        }}
        onClick={addNewProductToList}
        key={`addProductButtonKey`}
      >
        <i className="bi bi-plus-square" style={{ marginRight: "0.5em" }}></i> Tambah Produk
      </button>
    </div>
  );

  let purchaseTotalValue = undefined;
  let purchaseTotal = "Rp -";

  for (let rowIndex = 0; rowIndex < purchaseTableData.length; rowIndex++) {
    let nominal = parseInt(purchaseTableData[rowIndex].product_subtotal.replace("Rp ", "").replaceAll(",", ""));
    if (isNaN(nominal) !== true) {
      if (purchaseTotalValue === undefined) purchaseTotalValue = 0;
      purchaseTotalValue += nominal;
    }
  }
  if (purchaseTotalValue === undefined) {
    purchaseTotal = `Rp -`;
  } else {
    purchaseTotal = `Rp ${formatCurrency(purchaseTotalValue)}`;
  }

  // Submit Pembelian Functionality
  const [addPembelianMessage2, setAddPembelianMessage2] = useState([0, ""]);
  const [newPembelianSubmitData, setNewPembelianSubmitData] = useState({});
  const [callSubmitNewPembelian, setCallSubmitNewPembelian] = useState(0);
  useFunctionsFetch(
    undefined,
    "/purchases/create",
    newPembelianSubmitData,
    optionsBinding["add-purch"],
    callSubmitNewPembelian,
    (res) => {
      setCallSubmitNewPembelian((prev) => { return prev - 1; })
      if (res.failed) {
        setAddPembelianMessage2(
          [2, `Terjadi kesalahan dalam penambahan pembelian kepada sistem! ( ${res.failed} )`]
        )
        setTimeout(() => { setAddPembelianMessage2([0, ""]); }, 10000);
        return;
      } else if (res.acknowledged !== true) {
        setAddPembelianMessage2(
          [2, `Terjadi kesalahan dalam penambahan pembelian kepada sistem! ( Uncorrespondent: [ ${res.acknowledged}, ${res.bulkRes.nModified}/${purchaseTableData.length} ] )`]
        )
        setTimeout(() => { setAddPembelianMessage2([0, ""]); }, 10000);
        return;
      } else {
        setAddPembelianMessage2([1, "Pembelian berhasil ditambahkan kepada sistem!"])
        setTimeout(() => {
          setAddPembelianMessage2([0, ""]);
          setCallSyncPurchaseCount((prev) => { return prev + 1; })
          setPembelianData({ ...pembelianDataBlank })
          setPurchaseTableData([])
        }, 3000);
      }
    }
  )

  function submitPembelianHandler() {
    let error = undefined;

    if ((!pembelianData.pur_id) && (error === undefined)) { error = `Pembelian tidak mempunyai ID yang valid!`; }
    if ((!pembelianData.pur_date) && (error === undefined)) { error = `Pembelian tidak mempunyai tanggal yang valid!`; }
    if ((!pembelianData.pur_payment) && (error === undefined)) { error = `Pembelian tidak mempunyai pembayaran yang valid!`; }
    if (((!pembelianData.pur_supplier) || (!suppliersData.includes(pembelianData.pur_supplier)))
      && (error === undefined)) { error = `Pembelian tidak mempunyai supplier yang valid!`; }
    if ((purchaseTableData.length === 0) && (error === undefined)) { error = `Pembelian tidak mempunyai produk!`; }

    let processedTableData = purchaseTableData.map((indivProduct, idx) => {

      if ((!indivProduct.pid) && (error === undefined)) { error = `Produk nomor ${idx + 1} bukan produk yang terdaftar pada sistem!`; }
      if (((!indivProduct.product_ed) || (parseInt(indivProduct.product_ed.split("-")[0]) < 2000)) && (error === undefined)) { error = `Produk nomor ${idx + 1} tidak mempunyai expiry date yang valid!`; }
      if ((!indivProduct.p_price) && (error === undefined)) { error = `Produk nomor ${idx + 1} tidak mempunyai harga pembelian yang valid!`; }
      if ((!indivProduct.purchasequantity) && (error === undefined)) { error = `Produk nomor ${idx + 1} tidak mempunyai jumlah pembelian yang valid!`; }
      if ((!indivProduct.purchase_format) && (error === undefined)) { error = `Produk nomor ${idx + 1} tidak mempunyai unit / satuan yang valid!`; }

      let calculatedQty = (indivProduct.purchase_format === indivProduct.product_normal_packaging) ?
        indivProduct.purchasequantity * indivProduct.product_normal_qty_pack : indivProduct.purchasequantity;

      return {
        pid: indivProduct.pid,
        expirydate: indivProduct.product_ed,
        p_price: indivProduct.p_price,
        purchase_item_quantity: indivProduct.purchasequantity,
        purchasequantity: parseInt(calculatedQty)
      }
    })

    try {
      processedTableData = processedTableData.map((purchasedProduct) => {
        purchasedProduct = {
          ...purchasedProduct,
          pur_price: parseInt(purchasedProduct.p_price),
          std_pur_qty: parseInt(purchasedProduct.purchasequantity)
        }
        delete purchasedProduct.p_price;
        delete purchasedProduct.purchasequantity;
        return purchasedProduct
      })
    } catch (intError) {
      error = "Terjadi kesalahan dalam pemrosesan harga pembelian atau jumlah pembelian!"
    }

    processedTableData = processedTableData.filter((element) => (element))

    if ((processedTableData.length !== purchaseTableData.length) || (error !== undefined)) {
      setAddPembelianMessage2([2, error ? error : "Terjadi kesalahan dalam penambahan pembelian kepada sistem!"])
      setTimeout(() => { setAddPembelianMessage2([0, ""]); }, 10000);
      return;
    }

    setNewPembelianSubmitData({
      ...pembelianData,
      pur_date: new Date(`${new Date(pembelianData.pur_date).toISOString().split("T")[0]}T12:00:00.000Z`),
      purchasedProducts: processedTableData
    })

    setCallSubmitNewPembelian((prev) => { return prev + 1; });

  }

  // Pembayaran and Pembayaran Search Functionality
  const [pembayaranSearch, clearPembayaranSearch, queryPembayaranSearch, pembayaranKeyDownHandler] = useSearchProvider(
    [{ pembayaran: "Cash" }, { pembayaran: "Bank" }, { pembayaran: "Payable" }], "pembayaran", setPembelianData, "pur_payment");

  // Rendered Component
  return (
    <div className="tambahpembelian">
      {view === "getPembelianID" ?
        <div>

        </div>
        : 
        <div>
          <h3 className="tabHeading">Retur Pembelian</h3>

          <h5 className="sectionHeading mb-4">Informasi Pembelian</h5>

          <form autoComplete="off" style={{ marginBottom: "2em" }}>
            <div style={{ display: "flex" }}>
              <div style={{ width: "150px" }}>
                <label htmlFor="pur_id">ID <span className="reqStar">*</span></label><br />
                <input id="pur_id" type="text" onChange={handlePembelianFormChange} value={pembelianData.pur_id} disabled />
              </div>
              <div style={{ width: "180px" }}>
                <label htmlFor="pur_date">Tanggal <span className="reqStar">*</span></label><br />
                <input id="pur_date" type="date" onChange={handlePembelianFormChange} value={pembelianData.pur_date} />
              </div>
              <div style={{ width: "160px" }}>
                <label htmlFor="pur_payment">Pembayaran <span className="reqStar">*</span></label><br />
                <input
                  id="pur_payment"
                  type="text"
                  onChange={() => { queryPembayaranSearch(""); }}
                  value={pembelianData.pur_payment}
                  readOnly={true}
                  style={{ cursor: "pointer" }}
                  onFocus={() => { queryPembayaranSearch(""); }}
                  onBlur={() => { clearPembayaranSearch(); }}
                  onKeyDown={pembayaranKeyDownHandler}
                />
                {pembayaranSearch.length > 0 ?
                  <div className={`pembayaran searchContainer`}>
                    {pembayaranSearch}
                  </div>
                  : ""}
              </div>
              <div style={{ flexGrow: "1" }}>
                <label htmlFor="pur_supplier">Supplier <span className="reqStar">*</span></label><br />
                <input
                  id="pur_supplier"
                  type="text"
                  onChange={handlePembelianFormChange}
                  value={pembelianData.pur_supplier}
                  onFocus={() => { querySupplierSearch(pembelianData.pur_supplier) }}
                  onBlur={() => { clearSupplierSearch() }}
                  onKeyDown={supplierKeyDownHandler}
                />
                {supplierSearch.length > 0 ?
                  <div className={`supplier searchContainer`}>
                    {supplierSearch}
                  </div>
                  : ""}
              </div>
            </div>

          </form>

          <OperationMessage status={addPembelianMessage1[0]} msg={addPembelianMessage1[1]} customStyle={{ margin: "0 0 2em 0" }} />

          <h5 className="sectionHeading">Daftar Produk</h5>

          <div className="customTable" style={{ marginBottom: "2em" }}>
            <TableRow columns={[
              ["3.5%", "No"],
              ["33%", "Produk"],
              ["13.5%", "Expired Date"],
              ["12%", "Harga Beli"],
              ["1.5%", ""],
              ["5.5%", "Qty"],
              ["10%", "Unit"],
              ["2%", ""],
              ["13%", "Subtotal"],
              ["6%", ""]
            ]} />
            {shownRows}
            <hr style={{ marginTop: "-1px" }} />
            <TableRow columns={[
              ["3.5%", ""],
              ["33%", ""],
              ["13.5%", ""],
              ["12%", ""],
              ["1.5%", ""],
              ["5.5%", ""],
              ["10%", "Total"],
              ["2%", ""],
              ["13%", purchaseTotal],
              ["6%", ""]
            ]} ending="true" />

          </div>

          <OperationMessage status={addPembelianMessage2[0]} msg={addPembelianMessage2[1]} customStyle={{ margin: "0 0 2em 0" }} />

          <Button
            className="submitPembelianBtn ms-auto"
            iconClasses="bi bi-cart"
            btnText="Tambah Pembelian"
            clickHandler={submitPembelianHandler}
            type="button"
          />

        </div>
      }

    </div>
  )
}